@import 'libs/shared/src/styles/core';

/* 3rd party libraries */
@import 'timepicker/jquery.timepicker';
@import 'ui-select/dist/select';

/* Shared styles */
@import 'libs/shared/src/styles/fonts/inter-v18';
@import 'libs/shared/src/styles/modal';
@import 'libs/shared/src/styles/lightbox';
@import 'libs/shared/src/styles/standalone-service-layout/standalone-service-layout';
@import 'libs/shared/src/styles/tippy';
@import 'libs/shared/src/styles/pools-reservation';

/* Public styles */
@import 'layout';
@import 'forms';
@import 'login';
