@import 'libs/shared/src/styles/component-common';

$lightbox-header-height: 84px;
$lightbox-bottom-spacing: 30px;
$lightbox-width: 100vw;

.lightbox {
  &.modal .modal-dialog,
  &.modal.in .modal-dialog,
  &.modal.fade .modal-dialog,
  &.modal.fade.in .modal-dialog {
    // Fixes a bug in chrome.
    // When some element inside the modal has position:fixed, the transforms have a weird effect of miscalculating the element's position
    transform: none;
    // Overriding standard animation - we only use opacity for lightbox, no sliding in
    transition: opacity $avr-animation-duration $avr-easing;
  }

  .modal-dialog {
    width: auto;
    margin: 0;
  }

  // Overriding standard header style to achieve a fixed lightbox-style header
  .modal-header {
    //background: linear-gradient(to bottom, rgba($avr-color-brand-black, 0.6), rgba($avr-color-brand-black, 0.2) 75%, transparent);
    background: linear-gradient(to bottom, $avr-color-black, rgba(41, 41, 41, 0.64), rgba(41, 41, 41, 0.15), rgba(41, 41, 41, 0));
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: $z-index-main;
    width: 100vw;
    height: $lightbox-header-height;
    padding: $avr-space-s 0;
    text-align: center;

    .close {
      display: none;
    }
  }

  // Making modal content container transparent
  .modal-content {
    border: none;
    box-shadow: none;
    background: none;
  }

  .modal-backdrop {
    background-color: rgba($avr-color-brand-black, 0.6);
  }

  .modal-body {
    padding: 0;
    width: $lightbox-width;
  }
}
