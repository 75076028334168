@import 'libs/shared/src/styles/variables';

html,
body {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.main-layout {
  min-height: 100%;

  @include tablet {
    display: flex;
  }

  &--vertical {
    flex-direction: column;
  }

  &__content {
    margin-top: $avr-main-navigation-header-height;
    background: $avr-color-white;
    padding: $avr-space-m;
    position: relative;

    @include tablet {
      flex: 1 0 0%;
      margin: $avr-space-m;
      // allows the flex item to shrink even if the content is wider
      min-width: 0;
    }

    &--overview {
      box-shadow: none;
      margin: 0;

      @include mobile-only {
        margin-top: 52px;
      }
    }

    &--condensed {
      padding: 0;
    }

    &--overview,
    &--condensed {
      .info-banner-container avr-api-error avr-api-error-target .alert.avr-alert.alert-danger.alert-api-error,
      .info-banner-container avr-upgrade-banner .upgrade-banner,
      .info-banner-container outdated-browser-message .alert.avr-alert.alert-warning {
        margin: (-$avr-space-m) (-$avr-space-m) $avr-space-m;
      }
    }

    &--header {
      padding: 0;
      margin: 0;

      .info-banner-container avr-api-error avr-api-error-target .alert.avr-alert.alert-danger.alert-api-error,
      .info-banner-container avr-upgrade-banner .upgrade-banner,
      .info-banner-container outdated-browser-message .alert.avr-alert.alert-warning {
        margin: 0;
      }
    }
  }
}

.info-banner-container {
  avr-api-error avr-api-error-target .alert.avr-alert.alert-danger.alert-api-error,
  avr-upgrade-banner .upgrade-banner,
  outdated-browser-message .alert.avr-alert.alert-warning {
    margin: (-$avr-space-xl) (-$avr-space-xl) $avr-space-xl;
  }
}

$page-fixed-header: 80px;
$page-fixed-sidebar: 280px;

.page-fixed {
  display: flex;
  flex-direction: column;
  height: 100%;

  & &__pre-header {
    width: 100%;
    flex: 0 0 auto;
    padding: $avr-space-m;
    border-bottom: $avr-border-width-100-gray-40;
  }

  & &__header {
    flex: 0 0 auto;
    width: 100%;
    height: $page-fixed-header;
    padding: 0 $avr-space-m;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $avr-border-width-100-gray-40;

    h3 {
      margin: 0;
    }
  }

  & &__content {
    flex: 1 0 0%;
    display: flex;
    min-width: 0;
    min-height: 0;

    @include mobile-only {
      flex-direction: column;
    }

    &--center {
      align-items: center;
      justify-content: center;
    }

    &-left {
      flex: 0 0 $page-fixed-sidebar;
      overflow-y: scroll;
      border-right: $avr-border-width-100-gray-40;
    }

    &-right {
      flex: 1 1 auto;
      overflow-y: scroll;
    }
  }

  & &__navigation {
    margin-bottom: $avr-space-m;

    &__item {
      display: block;
      padding-left: $avr-space-m;
      position: relative;
      border-left: 3px solid transparent;
      transition: background-color $avr-animation-duration, border-color $avr-animation-duration;

      &:not(:hover) {
        cursor: pointer;
      }

      &:hover {
        background-color: $avr-color-gray-95;
      }

      &--selected,
      &--selected:hover {
        border-left-color: $avr-color-blue-50;
        background-color: $avr-color-blue-90;
      }
    }

    .page-fixed__navigation__item + .page-fixed__navigation__item {
      margin-top: 1px;

      &:before {
        content: '';
        width: calc(100% - #{$avr-space-m});
        height: 1px;
        background-color: $avr-color-gray-90;
        position: absolute;
        top: -1px;
        right: 0;
      }
    }

    &__item-title {
      font-size: $avr-font-size-smallish;
      font-weight: $avr-font-weight-semibold;
      padding: $avr-space-m $avr-space-m 0 0;
      margin-bottom: $avr-space-xs;
      color: $avr-color-gray-10;
    }

    &__item-description {
      font-size: $avr-font-size-small;
      padding: 0 $avr-space-m $avr-space-m 0;
      color: $avr-color-gray-30;
    }
  }
}

.main-layout-wrapper {
  height: 100%;
}
