.input-group {
  // All inputs are floated, clear the floating here if the avr-input has only one input
  .avr-input {
    .form-control {
      display: block;
      float: none;
    }
  }

  // General for all addons
  .input-group-addon {
    font-size: $avr-font-size-smallish;
    color: $avr-color-gray-30;
    box-shadow: 12px 0 0 12px rgba($avr-color-brand-black, 0);
    transition: border-color ease-in-out 0.15s;
    border-left: none;
    padding: 0 8px;

    .avricon {
      color: initial;
    }
  }

  // Don't apply the styles if a form-control behaves like a button (in some cases form-control has a btn class on it
  // e.g. ui-select) or if the input-group has an input-group-btn
  &:not(#{&}--with-group-btn) {
    .form-control:not(.btn) {
      border-right: 0;
      box-shadow: -12px 0 0 12px rgba($avr-color-brand-black, 0);

      &:focus {
        box-shadow: -1px -1px 0 0 $input-border-focus, -1px 0 0 0 $input-border-focus, -1px 1px 0 0 $input-border-focus;

        ~ .input-group-addon { // stylelint-disable-line max-nesting-depth
          box-shadow: -1px -1px 0 0 $input-border-focus, 0 0 0 1px $input-border-focus, -1px 1px 0 0 $input-border-focus;
          border-color: $input-border-focus;
          background-color: $input-bg-focus;
          transition: all 0.24s $control-transition;
        }
      }

      .has-error & {
        &:focus { // stylelint-disable-line max-nesting-depth
          box-shadow: -1px -1px 0 0 $input-danger-border-focus, -1px 0 0 0 $input-danger-border-focus, -1px 1px 0 0 $input-danger-border-focus;

          ~ .input-group-addon { // stylelint-disable-line max-nesting-depth
            box-shadow: -1px -1px 0 0 $input-danger-border-focus, 0 0 0 1px $input-danger-border-focus, -1px 1px 0 0 $input-danger-border-focus;
            border-color: $input-danger-border-focus;
            background-color: $input-danger-bg;
          }
        }
      }

      // Use ng-valid to prevent date time component from highlighting valid inputs on error
      .has-error &:not(.form-control--novalidate).ng-valid {
        &:focus { // stylelint-disable-line max-nesting-depth
          box-shadow: -1px -1px 0 0 $input-border-focus, -1px 0 0 0 $input-border-focus, -1px 1px 0 0 $input-border-focus;

          ~ .input-group-addon { // stylelint-disable-line max-nesting-depth
            box-shadow: -1px -1px 0 0 $input-border-focus, 0 0 0 1px $input-border-focus, -1px 1px 0 0 $input-border-focus;
            border-color: $input-border-focus;
            background-color: $input-bg-focus;
          }
        }
      }
    }
  }
}

.input-group-btn {
  vertical-align: top;

  .btn {
    height: 34px;
  }
}
