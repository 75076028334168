@import 'libs/shared/src/styles/component-common';

.login-error-block {
  color: $avr-color-red-50;
  text-align: left;
  margin-top: $avr-space-s;
}

.login-help,
.login-help-item {
  margin-top: $avr-space-xl;
}

.login-form {
  .form-control {
    margin-top: -1px;
  }

  .avr-input {
    margin-bottom: 0;
  }
}

.reset-error {
  color: $avr-color-red-50;
  text-align: left;
}
