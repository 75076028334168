@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/shift-away.css';
@import 'tippy.js/animations/shift-toward.css';

.popover {
  &__trigger {
    display: inline-block;
  }
}

.tooltip {
  &__content {
    margin-bottom: 0;
    font-size: $avr-font-size-small;
  }
}

.tippy-backdrop {
  width: 100%;
  height: 100%;
  background-color: $avr-depth-z200-backdrop;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-above-modal;
  animation: fade-in $avr-animation-duration $avr-easing;
}

.tippy-box[data-theme~='avrios'],
.tippy-box[data-theme~='avrios-context-menu'],
.tippy-box[data-theme~='avrios-context-menu-with-overflow'],
.tippy-box[data-theme~='avrios-health-chart'],
.tippy-box[data-theme~='avrios-health-badge'],
.tippy-box[data-theme='avrios-feature-tour'],
.tippy-box[data-theme='avrios-borderless'],
.tippy-box[data-theme='avrios-reduced-padding'] {
  background-color: $avr-color-white;
  background-clip: padding-box;
  color: $text-color;
  box-shadow: $avr-depth-z200-shadow;
}

.tippy-box[data-theme~='avrios'],
.tippy-box[data-theme~='avrios-context-menu'],
.tippy-box[data-theme~='avrios-context-menu-with-overflow'],
.tippy-box[data-theme~='avrios-health-chart'],
.tippy-box[data-theme~='avrios-health-badge'],
.tippy-box[data-theme='avrios-feature-tour'],
.tippy-box[data-theme='avrios-reduced-padding'] {
  border: 1px solid rgba($avr-color-gray-10, 0.2);
  border-radius: $avr-border-radius-50;
}

.tippy-box[data-theme~='avrios'],
.tippy-box[data-theme='avrios-feature-tour'] {
  .tippy-content {
    padding: $avr-space-xl;
  }
}

.tippy-box[data-theme='avrios-reduced-padding'] {
  .tippy-content {
    padding: $avr-space-s;
  }
}

.tippy-box[data-theme~='avrios-context-menu'],
.tippy-box[data-theme~='avrios-context-menu-with-overflow'],
.tippy-box[data-theme~='avrios-borderless'] {
  max-height: 100vh;
  overflow: auto;

  .tippy-content {
    padding: 0;

    avr-context-menu-group + avr-context-menu-group {
      margin-top: 1px;

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: $avr-color-gray-90;
        margin-top: -1px;
        position: absolute;
        left: 0;
      }
    }
  }

  .tippy-box[data-theme='avrios-feature-tour'][data-placement='right'] {
    margin-left: $avr-space-xl;
  }
}

.tippy-box[data-theme~='avrios-context-menu-with-overflow'] {
  overflow: visible;
}

.tippy-box[data-theme~='avrios-health-chart'] {
  max-width: initial !important;
}

.tippy-box[data-theme~='avrios-health-badge'] {
  max-width: initial !important;
}
