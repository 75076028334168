// Intercom uses the max integer number as the z-index which cannot be
// overriden, so set the z-index to something normal with !important
// so that other elemets can appear over it (etc. mobile main navigation)

#intercom-container {
  z-index: $z-index-intercom !important;

  .modal-open & {
    @include desktop() {
      // Only on desktops, when we have a modal open, the intercom button should be shown
      // over the modal background
      z-index: $zindex-modal-background + $z-index-intercom !important;
    }
  }
}

.modal-open.blur {
  .intercom-app {
    & > div,
    & > iframe {
      z-index: $z-index-intercom !important;
    }
  }

  .intercom-lightweight-app {
    z-index: $z-index-intercom !important;
  }
}
