@import 'libs/shared/src/styles/component-common';

html,
body,
.layout-wrapper,
.layout-wrapper > .container {
  height: 100%;
}

body {
  background: $avr-color-white;
}

/* Deferred bootstrapping styles + spinners */
#spinner-placeholder {
  display: none;
}

.deferred-bootstrap-loading #spinner-placeholder {
  display: block !important;
  height: 160px;
}

#bootstrap-error {
  display: none;
}

.deferred-bootstrap-error {
  #bootstrap-error {
    display: block !important;
    margin-top: $avr-space-l;
    font-weight: $avr-font-weight-semibold;
  }

  .spinner {
    display: none !important;
  }
}

.account-type-link {
  margin: 8px auto;
  text-align: center;

  & > a {
    cursor: pointer;
    color: $avr-color-white;
  }
}

.alert-api-error.off-screen {
  top: $avr-space-m;
  left: initial;
  right: initial;

  @media (min-width: $screen-sm-min) {
    width: ($container-sm - ($avr-space-m * 2));
  }

  @media (min-width: $screen-md-min) {
    width: ($container-md - ($avr-space-m * 2));
  }

  @media (min-width: $screen-lg-min) {
    width: ($container-lg - ($avr-space-m * 2));
  }
}

.simple-layout {
  @import 'libs/shared/src/styles/simple-layout';

  .page-section:first-of-type {
    margin-top: 0;

    &:before {
      content: none;
    }
  }
}
