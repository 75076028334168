/* Should only be used for component based styles
// NOTE: don't include any files that include classes
// as this will result having duplicated css properties */

/* Boostrap */
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/_mixins';

/* Avricons */
@import 'libs/shared/src/styles/icons/avricons-mixins';
@import 'libs/shared/src/styles/icons/generated/avricons-variables';

/* Avrios */
@import 'libs/shared/src/styles/variables';
@import 'libs/shared/src/styles/mixins';
@import 'libs/shared/src/styles/z-index';
@import 'libs/shared/src/styles/media-breakpoints';
