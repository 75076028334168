// Spinner container with minimum height and centered spinner
.spinner-placeholder {
  position: relative;
  min-height: 100px;

  &-hidden {
    display: none;
  }

  &-fullscreen {
    position: fixed;
    top: 0;
    left: $avr-main-navigation-width;
    right: 0;
    bottom: 0;
    background: $avr-color-white;
  }

  &--splash-screen {
    background: transparent;
    left: 0;

    .avr-icon-spinner {
      top: 75%;
    }
  }

  &__logo {
    width: 150px;
    height: 150px;
    background: url('~@shiftmove/design-system-toolkit/public/assets/logos/avrios-logomark-brand.svg') 50% 50% no-repeat;
    background-size: contain;
    margin: -75px 0 0 -75px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
  }
}

.avr-icon-spinner {
  @include spinner($size: 24px, $color: $avr-color-blue-50, $center: true);

  &--small {
    @include spinner($size: 16px, $color: $avr-color-blue-50, $center: true);
  }

  &--inline {
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
  }
}

.avr-icon-spinner-light {
  @include spinner($size: 24px, $center: true);
}

.btn.btn-spinner {
  position: relative;
  color: transparent;

  &:before {
    @include spinner($center: true);

    content: '';
  }

  &.btn-default:before {
    @include spinner($color: $avr-color-gray-10, $center: true);
  }

  &.btn-link {
    &:hover {
      color: transparent;
    }

    &:before {
      @include spinner($color: $avr-color-blue-50, $center: true);

      position: absolute;
      left: 8px; // half of the spinner diameter
    }
  }
}
