.bulk-edit-row {
  @include clearfix();
}

.bulk-edit-option {
  float: left;
  width: 25px;
}

.bulk-edit-value {
  float: left;
  width: calc(100% - 30px);

  & > h4 {
    // Align with checkbox
    margin-top: 1px;
  }
}