a {
  cursor: pointer;
}

a.disabled {
  cursor: default;
  text-decoration: none;
}

// If we disable a link it should not be clickable
a[disabled] {
  pointer-events: none;
}

a.link {
  text-decoration: underline;
}
