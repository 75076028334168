// generic ajax request loading bar
.page-loading-bar {
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-loading-bar;
  position: fixed;
  background: transparent;
  border: none;

  .bar {
    height: 3px;
    background-color: $avr-color-blue-50;
    transition: width 0.35s ease;
    border-radius: $avr-border-radius-25;
  }
}
