.progress-animation {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;

  &:before,
  &:after {
    content: '';
    border: 1px $avr-color-blue-50 solid;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
  }

  &:before {
    transform: scale(1);
    opacity: 1;
    animation: progressAnimationBefore 2s infinite linear;
  }

  &:after {
    transform: scale(0);
    opacity: 0;
    animation: progressAnimationAfter 2s infinite linear;
  }

  &.progress-animation-as-link {
    right: 2px;
    bottom: 1px;
  }

  &.progress-animation-big {
    width: 20px;
    height: 20px;
    bottom: 2px;
  }
}
