.contact {
  line-height: 24px;
  margin-top: -2px;
  margin-bottom: -2px;

  .badge {
    font-size: $avr-font-size-base;
    vertical-align: initial;
  }
}
