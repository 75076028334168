@use '@angular/material' as mat;
@use '@shiftmove/design-system-toolkit/public/tokens/scss/ShiftmoveDesignSystemFont' as ShiftmoveFont;

@import '@shiftmove/design-system-toolkit/public/tokens/scss/ShiftmoveDesignSystemColor';
@import 'libs/shared/src/styles/component-common';

@include mat.core();

$dark-primary-text: rgba($avr-color-black, 0.87);
$dark-secondary-text: rgba($avr-color-black, 0.54);
$dark-disabled-text: rgba($avr-color-black, 0.21);
$dark-dividers: rgba($avr-color-black, 0.12);
$dark-focused: rgba($avr-color-black, 0.12);
$light-primary-text: $avr-color-white;
$light-secondary-text: rgba($avr-color-white, 0.7);
$light-disabled-text: rgba($avr-color-white, 0.5);
$light-dividers: rgba($avr-color-white, 0.12);
$light-focused: rgba($avr-color-white, 0.12);

$error-palette: (
  50: $shiftmove-color-error-90,
  100: $shiftmove-color-error-80,
  200: $shiftmove-color-error-70,
  300: $shiftmove-color-error-60,
  400: $shiftmove-color-error-50,
  500: $shiftmove-color-error-40,
  600: $shiftmove-color-error-30,
  700: $shiftmove-color-error-20,
  800: $shiftmove-color-error-10,
  900: $shiftmove-color-error-0,
  a100: $shiftmove-color-error-90,
  a200: $shiftmove-color-error-80,
  a400: $shiftmove-color-error-60,
  a700: $shiftmove-color-error-30,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    a100: $dark-primary-text,
    a200: $light-primary-text,
    a400: $light-primary-text,
    a700: $light-primary-text,
  )
);

$success-palette: (
  50: $shiftmove-color-success-90,
  100: $shiftmove-color-success-80,
  200: $shiftmove-color-success-70,
  300: $shiftmove-color-success-60,
  400: $shiftmove-color-success-50,
  500: $shiftmove-color-success-40,
  600: $shiftmove-color-success-30,
  700: $shiftmove-color-success-20,
  800: $shiftmove-color-success-10,
  900: $shiftmove-color-success-0,
  a100: $shiftmove-color-success-90,
  a200: $shiftmove-color-success-80,
  a400: $shiftmove-color-success-60,
  a700: $shiftmove-color-success-30,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    a100: $dark-primary-text,
    a200: $light-primary-text,
    a400: $light-primary-text,
    a700: $light-primary-text,
  )
);

$brand-palette: (
  50: $shiftmove-color-brand-90,
  100: $shiftmove-color-brand-80,
  200: $shiftmove-color-brand-70,
  300: $shiftmove-color-brand-60,
  400: $shiftmove-color-brand-50,
  500: $shiftmove-color-brand-40,
  600: $shiftmove-color-brand-30,
  700: $shiftmove-color-brand-20,
  800: $shiftmove-color-brand-10,
  900: $shiftmove-color-brand-0,
  a100: $shiftmove-color-brand-90,
  a200: $shiftmove-color-brand-80,
  a400: $shiftmove-color-brand-60,
  a700: $shiftmove-color-brand-30,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    a100: $dark-primary-text,
    a200: $light-primary-text,
    a400: $light-primary-text,
    a700: $light-primary-text,
  )
);

$avr-error: mat.define-palette($error-palette, 500, 400, 600);
$avr-success: mat.define-palette($success-palette, 500, 400, 600);
$avr-brand: mat.define-palette($brand-palette, 500, 400, 600);

$avr-error-light: mat.define-palette($error-palette, 400);
$avr-success-light: mat.define-palette($success-palette, 400);
$avr-brand-light: mat.define-palette($brand-palette, 400);

/* stylelint-disable function-parentheses-newline-inside, function-comma-newline-after, function-parentheses-space-inside, function-comma-space-after   */

$typography-input-level: mat.define-typography-level(
  $font-family: #{ShiftmoveFont.$shiftmove-font-family-plain},
  $font-weight: #{ShiftmoveFont.$shiftmove-font-weight-plain-default},
  $font-size: #{$avr-font-size-base},
  $line-height: 20px,
  $letter-spacing: 0.05px
);

$my-custom-typography-config: mat.define-typography-config(
  $font-family: #{ShiftmoveFont.$shiftmove-font-family-plain},
  $body-1: $typography-input-level
);

$my-theme-warn-red: mat.define-light-theme((
  color: (
    primary: $avr-brand,
    accent: $avr-success,
    warn: $avr-error
  ),
  typography: $my-custom-typography-config,
  density: 0
));

$my-theme-light: mat.define-light-theme((
  color: (
    primary: $avr-brand-light,
    accent: $avr-success-light,
    warn: $avr-error-light
  )
));

/* stylelint-enable function-parentheses-newline-inside, function-comma-newline-after, function-parentheses-space-inside, function-comma-space-after   */

@include mat.all-component-themes($my-theme-warn-red);

/**
  * Customised component styles
*/
@include mat.form-field-density(-2);
@include mat.form-field-color($my-theme-light);
@include mat.select-color($my-theme-light);
@include mat.pseudo-checkbox-color($my-theme-light);

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  padding: 0;
}

.cdk-overlay-container {
  z-index: $zindex-above-modal !important;
}

mat-progress-bar {
  border-radius: $avr-space-s;
}

avr-button-toggle-group-material {
  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 44px;
    left: 2px;
  }

  .mat-button-toggle-group {
    border-radius: 3px;
    border-color: $shiftmove-color-neutral-80;
    color: $shiftmove-color-neutral-30;
    height: 44px;

    mat-button-toggle {
      height: 42px !important;
    }
  }

  .mat-button-toggle {
    border: 2px solid transparent;

    &:hover:not(.mat-button-toggle-disabled) {
      background-color: $shiftmove-color-neutral-80;
    }
  }

  mat-button-toggle-group .mat-button-toggle-checked {
    background-color: $shiftmove-color-neutral-98;
    border: 2px solid $shiftmove-color-neutral-98;
  }

  mat-button-toggle-group {
    width: 100%;

    mat-button-toggle {
      width: 100%;

      &.mat-button-toggle-checked {
        border: 1px solid $shiftmove-color-neutral-80 !important;
        border-left: 2px solid $shiftmove-color-neutral-80 !important;
      }

      .mat-button-toggle-button span {
        margin-top: -2px;
        margin-left: -2px;
      }
    }

    mat-button-toggle.avr-button-toggle-first.mat-button-toggle-checked:first-child {
      border: 1px solid $shiftmove-color-neutral-80 !important;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border-left: 1px solid $shiftmove-color-neutral-80 !important;
    }

    mat-button-toggle.avr-button-toggle-last.mat-button-toggle-checked:last-child {
      border: 1px solid $shiftmove-color-neutral-80 !important;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border-left: 2px solid $shiftmove-color-neutral-80 !important;
    }
  }

  mat-button-toggle-group:first-child {
    .mat-button-toggle-checked {
      border-left: 1px solid $shiftmove-color-neutral-80 !important;
    }

    .mat-button-toggle-checked.avr-button-toggle-middle {
      border-left: 2px solid $shiftmove-color-neutral-80 !important;
    }
  }

  mat-button-toggle-group mat-button-toggle .avr-button-toggle-middle.mat-button-toggle-checked {
    border-left: 2px solid $shiftmove-color-neutral-80 !important;
  }
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #{$shiftmove-color-brand-40};
  --mdc-linear-progress-track-color: rgba(0, 67, 206, 0.4);
}

mat-form-field {
  --mat-form-field-container-height: 44px;
}

.mat-mdc-form-field-error-wrapper {
  &:has(mat-error) {
    padding: 0;
  }
}

mat-error.mat-mdc-form-field-bottom-align:before {
  display: inline;
}
