@keyframes progressAnimationAfter {
  from {
    transform: scale(0.5);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes progressAnimationBefore {
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

// Spinner
@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

@keyframes widthProgressAnimation {
  0% { width: 0; }
  100% { width: 100%; }
}

// Placeholder loading for background color changes
@keyframes loadingPlaceholder {
  0% { background-position: -500px 0; }
  100% { background-position: 500px 0; }
}

// Chevron icons
@keyframes rotateLeft {
  0% {
    transform: rotate(-180deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotateRight {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
