$avr-left-nav-width: 200px;
$avr-logo-height: 22px;

body {
  background: $avr-color-white;
}

.layout-header {
  background: $avr-color-blue-50;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: $avr-navbar-height;
  z-index: $z-index-navigation;
  border-bottom: 1px solid $avr-color-black-alpha-05;

  .logo {
    height: $avr-navbar-height;
    width: $avr-left-nav-width;
    padding: (($avr-navbar-height - $avr-logo-height) * 0.5) $avr-space-m;
  }
}

.production-admin-login .layout-header {
  // this var is mostly used for text, but this is a deliberate exception
  background: $avr-color-red-40;
}

.layout-nav {
  width: $avr-left-nav-width;
  position: fixed;
  top: $avr-navbar-height;
  left: 0;
  bottom: 0;
  overflow: hidden;

  @include desktop {
    padding-top: $avr-space-m;
  }
}

section {
  margin-bottom: $avr-space-base * 6;

  &.section-border {
    padding-bottom: $avr-space-base * 6;
    border-bottom: $avr-border-width-100-gray-30;
  }
}

hr {
  border-top-color: $avr-color-gray-90;
}

h3.section-header {
  display: flex;
  flex-direction: row;
  gap: $avr-space-s;
}

.section-content {
  margin-top: $avr-space-base * 4;
}

// Mobile layout
@include mobile-only {
  body {
    background: $avr-color-white;
  }

  .layout-nav {
    width: 0;
    position: static;
  }

  .layout-header {
    // making sure the fixed header stays 100% viewport width on iOS even if the table breaks the layout
    max-width: 100vw;

    .logo {
      margin-left: 45px;
      text-align: left;

      img {
        margin-left: $avr-space-m;
      }
    }
  }
}

.initially-hidden {
  animation: fade-in 0.3s ease-out;
}

.init-error {
  padding: 50px 30px 15px 30px;

  .layout-header .logo {
    width: auto;

    img {
      margin: 0;
    }
  }
}

/**
 * Exists only to get IE11 to respect the min-height of the inner wrapper
 */
.flexible-height-outer-wrapper {
  display: flex;
  flex-direction: column;
}

/**
 * This gives us a page where the main body can stretch out with the content, but
 * the footer will always stay pinned to the bottom. The footer itself will also never
 * come higher than the bottom of the screen, and can be of flexible height
 */
.flexible-height-inner-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.flexible-height-content {
  flex: 1 0 auto;
  width: 100%;
}

.centered-desktop-container {
  max-width: 1000px;
  margin: 0 auto;
}
