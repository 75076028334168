@mixin mobile-only {
  @media (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin large {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin x-large {
  @media (min-width: $screen-xl) {
    @content;
  }
}

@mixin l-large {
  @media (min-width: $screen-l) {
    @content;
  }
}

@mixin height-m {
  @media (min-height: $screen-height-m) {
    @content;
  }
}

@mixin height-ls {
  @media (min-height: $screen-height-ls) {
    @content;
  }
}

@mixin height-l {
  @media (min-height: $screen-height-l) {
    @content;
  }
}
