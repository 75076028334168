.input-phone {
  .ui-select-choices-group-label {
    display: none;
  }

  .input-multiple-part-small {
    min-width: 80px;
  }

  .input-phone-calling-code {
    display: inline-block;
  }

  .has-explanation {
    .field-input {
      flex-basis: calc(100% - 36px);
      flex-grow: 0;
      flex-shrink: 1;
    }
  }
}
