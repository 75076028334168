@import 'libs/shared/src/styles/component-common';

.pools-reservation-wrapper {
  position: relative;
  width: 750px;
  margin: auto;

  hr {
    margin: $avr-space-xxl 0;
  }

  .section-split {
    margin: $avr-space-xxl 0 $avr-space-xl;
  }

  .step-heading {
    display: flex;
    align-items: center;
    margin-bottom: $avr-space-xl;

    .step-no {
      width: 40px;
      line-height: 40px;
      background-color: $avr-color-blue-50;
      display: block;
      margin-right: $avr-space-m;
      color: $avr-color-white;
      font-size: $avr-font-size-large;
      text-align: center;
      border-radius: 50%;
    }
  }

  .help-box {
    h3,
    h4,
    p:not(.text-muted) {
      margin-bottom: 0;
    }

    h4 {
      margin-top: $avr-space-xl;
    }
  }

  .pool-reservation-footer {
    margin-top: ($avr-space-xxl * 2);
  }
}

@include mobile-only {
  .pools-reservation-wrapper {
    width: 100%;

    h1,
    h2 {
      font-size: 18px;
    }

    h4 {
      font-size: 14px;
    }

    .property-list .avr-input .input-labeled-field,
    .property-list .avr-input .input-label {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .text-xs-center {
    text-align: center;
  }
}
