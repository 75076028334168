
.avricon-car-dashboard-gear:before {
  content: $avricon-car-dashboard-gear;
}

.avricon-vehicle-check:before {
  content: $avricon-vehicle-check;
}

.avricon-add-circle:before {
  content: $avricon-add-circle;
}

.avricon-add-square:before {
  content: $avricon-add-square;
}

.avricon-add-thin:before {
  content: $avricon-add-thin;
}

.avricon-aircraft-airship-side:before {
  content: $avricon-aircraft-airship-side;
}

.avricon-alert-triangle:before {
  content: $avricon-alert-triangle;
}

.avricon-alert:before {
  content: $avricon-alert;
}

.avricon-analytic-check:before {
  content: $avricon-analytic-check;
}

.avricon-analytic-currency:before {
  content: $avricon-analytic-currency;
}

.avricon-analytic-percentage:before {
  content: $avricon-analytic-percentage;
}

.avricon-animal-honey-comb:before {
  content: $avricon-animal-honey-comb;
}

.avricon-animal-pet-home:before {
  content: $avricon-animal-pet-home;
}

.avricon-astronomy-rocket:before {
  content: $avricon-astronomy-rocket;
}

.avricon-award-flag:before {
  content: $avricon-award-flag;
}

.avricon-bank-euro:before {
  content: $avricon-bank-euro;
}

.avricon-bank:before {
  content: $avricon-bank;
}

.avricon-bell-notifiaction-mute-disable:before {
  content: $avricon-bell-notifiaction-mute-disable;
}

.avricon-bell-notification-snooze:before {
  content: $avricon-bell-notification-snooze;
}

.avricon-bell-notification:before {
  content: $avricon-bell-notification;
}

.avricon-billing-check:before {
  content: $avricon-billing-check;
}

.avricon-bin-disabled:before {
  content: $avricon-bin-disabled;
}

.avricon-book-bookmark:before {
  content: $avricon-book-bookmark;
}

.avricon-book-contact-account:before {
  content: $avricon-book-contact-account;
}

.avricon-book-open-1:before {
  content: $avricon-book-open-1;
}

.avricon-brief-case-emphasis:before {
  content: $avricon-brief-case-emphasis;
}

.avricon-briefcase-plus:before {
  content: $avricon-briefcase-plus;
}

.avricon-briefcase:before {
  content: $avricon-briefcase;
}

.avricon-business-document-bill-finacial-move:before {
  content: $avricon-business-document-bill-finacial-move;
}

.avricon-business-document-bill-finacial:before {
  content: $avricon-business-document-bill-finacial;
}

.avricon-button-pause:before {
  content: $avricon-button-pause;
}

.avricon-calendar-edit:before {
  content: $avricon-calendar-edit;
}

.avricon-calendar-filled:before {
  content: $avricon-calendar-filled;
}

.avricon-camera-timer:before {
  content: $avricon-camera-timer;
}

.avricon-cash-briefcase:before {
  content: $avricon-cash-briefcase;
}

.avricon-check-box:before {
  content: $avricon-check-box;
}

.avricon-check-circle-inside:before {
  content: $avricon-check-circle-inside;
}

.avricon-check-double-thin:before {
  content: $avricon-check-double-thin;
}

.avricon-chevron-down-m:before {
  content: $avricon-chevron-down-m;
}

.avricon-chevron-left-m:before {
  content: $avricon-chevron-left-m;
}

.avricon-chevron-right-m:before {
  content: $avricon-chevron-right-m;
}

.avricon-chevron-up-m:before {
  content: $avricon-chevron-up-m;
}

.avricon-circle-double:before {
  content: $avricon-circle-double;
}

.avricon-circle-tilde:before {
  content: $avricon-circle-tilde;
}

.avricon-clock-fire-alarm:before {
  content: $avricon-clock-fire-alarm;
}

.avricon-clock-time-share:before {
  content: $avricon-clock-time-share;
}

.avricon-cloud-progress-quarter:before {
  content: $avricon-cloud-progress-quarter;
}

.avricon-cloud-upload:before {
  content: $avricon-cloud-upload;
}

.avricon-cloud-wind:before {
  content: $avricon-cloud-wind;
}

.avricon-coffee-cup:before {
  content: $avricon-coffee-cup;
}

.avricon-common-file-download:before {
  content: $avricon-common-file-download;
}

.avricon-common-file-duplicate:before {
  content: $avricon-common-file-duplicate;
}

.avricon-common-file:before {
  content: $avricon-common-file;
}

.avricon-common-files:before {
  content: $avricon-common-files;
}

.avricon-compass-navigator:before {
  content: $avricon-compass-navigator;
}

.avricon-compass-two-direction:before {
  content: $avricon-compass-two-direction;
}

.avricon-component-car-fuel-tank:before {
  content: $avricon-component-car-fuel-tank;
}

.avricon-component-car-key:before {
  content: $avricon-component-car-key;
}

.avricon-component-car-steering-wheel:before {
  content: $avricon-component-car-steering-wheel;
}

.avricon-component-car-wheel:before {
  content: $avricon-component-car-wheel;
}

.avricon-construction-backhoe:before {
  content: $avricon-construction-backhoe;
}

.avricon-construction-hoist-lifter:before {
  content: $avricon-construction-hoist-lifter;
}

.avricon-construction-truck:before {
  content: $avricon-construction-truck;
}

.avricon-contract-leasing:before {
  content: $avricon-contract-leasing;
}

.avricon-contract-purchase:before {
  content: $avricon-contract-purchase;
}

.avricon-contract-rent:before {
  content: $avricon-contract-rent;
}

.avricon-contract-star:before {
  content: $avricon-contract-star;
}

.avricon-credit-card-add:before {
  content: $avricon-credit-card-add;
}

.avricon-credit-card-disabled:before {
  content: $avricon-credit-card-disabled;
}

.avricon-credit-card-gas-fuel:before {
  content: $avricon-credit-card-gas-fuel;
}

.avricon-currency-coin-dollar:before {
  content: $avricon-currency-coin-dollar;
}

.avricon-currency-coin-euro-plus:before {
  content: $avricon-currency-coin-euro-plus;
}

.avricon-currency-euro-text:before {
  content: $avricon-currency-euro-text;
}

.avricon-currency-euro:before {
  content: $avricon-currency-euro;
}

.avricon-currency-krona-text:before {
  content: $avricon-currency-krona-text;
}

.avricon-currency-pound:before {
  content: $avricon-currency-pound;
}

.avricon-currency-swissfranc-text:before {
  content: $avricon-currency-swissfranc-text;
}

.avricon-currency-usd-dollar:before {
  content: $avricon-currency-usd-dollar;
}

.avricon-currency-zloty-text:before {
  content: $avricon-currency-zloty-text;
}

.avricon-cursor-select-circle:before {
  content: $avricon-cursor-select-circle;
}

.avricon-cursor-select-frame-out:before {
  content: $avricon-cursor-select-frame-out;
}

.avricon-cursor-select-frame:before {
  content: $avricon-cursor-select-frame;
}

.avricon-cursor:before {
  content: $avricon-cursor;
}

.avricon-dashboard-gas-empty:before {
  content: $avricon-dashboard-gas-empty;
}

.avricon-dashboard-gas-full:before {
  content: $avricon-dashboard-gas-full;
}

.avricon-dashboard-gas:before {
  content: $avricon-dashboard-gas;
}

.avricon-dashboard-gear-indicator-auto:before {
  content: $avricon-dashboard-gear-indicator-auto;
}

.avricon-dashboard-speed-guage:before {
  content: $avricon-dashboard-speed-guage;
}

.avricon-data-transfer-circle-upload:before {
  content: $avricon-data-transfer-circle-upload;
}

.avricon-data-transfer-content-unload:before {
  content: $avricon-data-transfer-content-unload;
}

.avricon-data-transfer-download:before {
  content: $avricon-data-transfer-download;
}

.avricon-data-transfer-square-upload:before {
  content: $avricon-data-transfer-square-upload;
}

.avricon-data-transfer-traffic-diagonal:before {
  content: $avricon-data-transfer-traffic-diagonal;
}

.avricon-data-transfer-traffic-horizontal:before {
  content: $avricon-data-transfer-traffic-horizontal;
}

.avricon-data-transfer-traffic-square:before {
  content: $avricon-data-transfer-traffic-square;
}

.avricon-data-transfer-traffic-vertical:before {
  content: $avricon-data-transfer-traffic-vertical;
}

.avricon-data-transfer-upload:before {
  content: $avricon-data-transfer-upload;
}

.avricon-design-tool-rotate-clockwise:before {
  content: $avricon-design-tool-rotate-clockwise;
}

.avricon-design-tool-rotate-counterclockwise:before {
  content: $avricon-design-tool-rotate-counterclockwise;
}

.avricon-disable-delete:before {
  content: $avricon-disable-delete;
}

.avricon-disable-remove-circle:before {
  content: $avricon-disable-remove-circle;
}

.avricon-dot:before {
  content: $avricon-dot;
}

.avricon-drive-start:before {
  content: $avricon-drive-start;
}

.avricon-email-envelope-1:before {
  content: $avricon-email-envelope-1;
}

.avricon-email-inbox-read:before {
  content: $avricon-email-inbox-read;
}

.avricon-email-send-envelope:before {
  content: $avricon-email-send-envelope;
}

.avricon-entertainment-ticket:before {
  content: $avricon-entertainment-ticket;
}

.avricon-equal:before {
  content: $avricon-equal;
}

.avricon-expand-all-direction:before {
  content: $avricon-expand-all-direction;
}

.avricon-expand-vertical:before {
  content: $avricon-expand-vertical;
}

.avricon-eye-disabled:before {
  content: $avricon-eye-disabled;
}

.avricon-favorite-heart:before {
  content: $avricon-favorite-heart;
}

.avricon-file-document:before {
  content: $avricon-file-document;
}

.avricon-file-favorite-star:before {
  content: $avricon-file-favorite-star;
}

.avricon-file-pdf-e-book:before {
  content: $avricon-file-pdf-e-book;
}

.avricon-file-question:before {
  content: $avricon-file-question;
}

.avricon-file-search-dollar:before {
  content: $avricon-file-search-dollar;
}

.avricon-file-text:before {
  content: $avricon-file-text;
}

.avricon-file-upload:before {
  content: $avricon-file-upload;
}

.avricon-filter-3:before {
  content: $avricon-filter-3;
}

.avricon-flash-enable-allow:before {
  content: $avricon-flash-enable-allow;
}

.avricon-flip-horizontal-right:before {
  content: $avricon-flip-horizontal-right;
}

.avricon-flip-vertical-down:before {
  content: $avricon-flip-vertical-down;
}

.avricon-focus:before {
  content: $avricon-focus;
}

.avricon-folder-dash:before {
  content: $avricon-folder-dash;
}

.avricon-globe-wireframe:before {
  content: $avricon-globe-wireframe;
}

.avricon-graph-bar-horizontal-left:before {
  content: $avricon-graph-bar-horizontal-left;
}

.avricon-graph-bar-horizontal:before {
  content: $avricon-graph-bar-horizontal;
}

.avricon-graph-bar-vertical:before {
  content: $avricon-graph-bar-vertical;
}

.avricon-graph-pie-1:before {
  content: $avricon-graph-pie-1;
}

.avricon-graph-stats-ascend:before {
  content: $avricon-graph-stats-ascend;
}

.avricon-graph-stats-descend:before {
  content: $avricon-graph-stats-descend;
}

.avricon-hashtag:before {
  content: $avricon-hashtag;
}

.avricon-hierarchy-circle-triangle:before {
  content: $avricon-hierarchy-circle-triangle;
}

.avricon-home-button-emphasis:before {
  content: $avricon-home-button-emphasis;
}

.avricon-home-button:before {
  content: $avricon-home-button;
}

.avricon-hourglass-flip:before {
  content: $avricon-hourglass-flip;
}

.avricon-hyperlink-break:before {
  content: $avricon-hyperlink-break;
}

.avricon-hyperlink:before {
  content: $avricon-hyperlink;
}

.avricon-id-card-disabled:before {
  content: $avricon-id-card-disabled;
}

.avricon-id-card:before {
  content: $avricon-id-card;
}

.avricon-image-file-jpg:before {
  content: $avricon-image-file-jpg;
}

.avricon-image-file-png:before {
  content: $avricon-image-file-png;
}

.avricon-image-file:before {
  content: $avricon-image-file;
}

.avricon-infinite-loop:before {
  content: $avricon-infinite-loop;
}

.avricon-information-circle:before {
  content: $avricon-information-circle;
}

.avricon-keyboard-arrow-back:before {
  content: $avricon-keyboard-arrow-back;
}

.avricon-keyboard-arrow-next:before {
  content: $avricon-keyboard-arrow-next;
}

.avricon-keyboard-down-arrow:before {
  content: $avricon-keyboard-down-arrow;
}

.avricon-keyboard-left-arrow:before {
  content: $avricon-keyboard-left-arrow;
}

.avricon-keyboard-up-arrow:before {
  content: $avricon-keyboard-up-arrow;
}

.avricon-kitchenware-fork-knife:before {
  content: $avricon-kitchenware-fork-knife;
}

.avricon-layers:before {
  content: $avricon-layers;
}

.avricon-layout-dashboard-4:before {
  content: $avricon-layout-dashboard-4;
}

.avricon-layout-three-columns:before {
  content: $avricon-layout-three-columns;
}

.avricon-layout-thumbnail-four:before {
  content: $avricon-layout-thumbnail-four;
}

.avricon-layout-thumbnail-nine:before {
  content: $avricon-layout-thumbnail-nine;
}

.avricon-layout-thumbnail-six:before {
  content: $avricon-layout-thumbnail-six;
}

.avricon-layout-thumbnail-three:before {
  content: $avricon-layout-thumbnail-three;
}

.avricon-layout-top-module:before {
  content: $avricon-layout-top-module;
}

.avricon-light-bulb:before {
  content: $avricon-light-bulb;
}

.avricon-line-chart-filled:before {
  content: $avricon-line-chart-filled;
}

.avricon-line-chart:before {
  content: $avricon-line-chart;
}

.avricon-list-bullet-point-circle:before {
  content: $avricon-list-bullet-point-circle;
}

.avricon-lock-square-unlock:before {
  content: $avricon-lock-square-unlock;
}

.avricon-lock-square:before {
  content: $avricon-lock-square;
}

.avricon-log-in-square:before {
  content: $avricon-log-in-square;
}

.avricon-log-out-square:before {
  content: $avricon-log-out-square;
}

.avricon-magnifier:before {
  content: $avricon-magnifier;
}

.avricon-map-pin-bell:before {
  content: $avricon-map-pin-bell;
}

.avricon-map-pin-favorite-star:before {
  content: $avricon-map-pin-favorite-star;
}

.avricon-maps-fold-pocket:before {
  content: $avricon-maps-fold-pocket;
}

.avricon-maps-location-pin:before {
  content: $avricon-maps-location-pin;
}

.avricon-maps-none-precision-gps:before {
  content: $avricon-maps-none-precision-gps;
}

.avricon-maps-pin-emphasis:before {
  content: $avricon-maps-pin-emphasis;
}

.avricon-maps-pin-thin:before {
  content: $avricon-maps-pin-thin;
}

.avricon-maps-pin:before {
  content: $avricon-maps-pin;
}

.avricon-maps-precision-gps:before {
  content: $avricon-maps-precision-gps;
}

.avricon-market-statistics-1:before {
  content: $avricon-market-statistics-1;
}

.avricon-maximize-square:before {
  content: $avricon-maximize-square;
}

.avricon-megaphone-1:before {
  content: $avricon-megaphone-1;
}

.avricon-menu-more-horizontal:before {
  content: $avricon-menu-more-horizontal;
}

.avricon-menu-more-vertical:before {
  content: $avricon-menu-more-vertical;
}

.avricon-message-chat-circle:before {
  content: $avricon-message-chat-circle;
}

.avricon-message-chat-content:before {
  content: $avricon-message-chat-content;
}

.avricon-message-chat-round-schedule-clock:before {
  content: $avricon-message-chat-round-schedule-clock;
}

.avricon-microsoft-excel-logo:before {
  content: $avricon-microsoft-excel-logo;
}

.avricon-motorbicycle-scooter:before {
  content: $avricon-motorbicycle-scooter;
}

.avricon-move-right-3:before {
  content: $avricon-move-right-3;
}

.avricon-music-player-button-pause:before {
  content: $avricon-music-player-button-pause;
}

.avricon-music-player-button-play:before {
  content: $avricon-music-player-button-play;
}

.avricon-music-player-volume-high:before {
  content: $avricon-music-player-volume-high;
}

.avricon-navigation-left-two:before {
  content: $avricon-navigation-left-two;
}

.avricon-navigation-right-two:before {
  content: $avricon-navigation-right-two;
}

.avricon-note-edit:before {
  content: $avricon-note-edit;
}

.avricon-notebook-pencil:before {
  content: $avricon-notebook-pencil;
}

.avricon-object-weight:before {
  content: $avricon-object-weight;
}

.avricon-office-book-shelf:before {
  content: $avricon-office-book-shelf;
}

.avricon-page-content:before {
  content: $avricon-page-content;
}

.avricon-paperclip-1:before {
  content: $avricon-paperclip-1;
}

.avricon-paragraph-indent-left:before {
  content: $avricon-paragraph-indent-left;
}

.avricon-paragraph-indent-right:before {
  content: $avricon-paragraph-indent-right;
}

.avricon-paragraph-left-align-disabled:before {
  content: $avricon-paragraph-left-align-disabled;
}

.avricon-paragraph-left-align:before {
  content: $avricon-paragraph-left-align;
}

.avricon-pencil-1:before {
  content: $avricon-pencil-1;
}

.avricon-phone-action-dismiss:before {
  content: $avricon-phone-action-dismiss;
}

.avricon-phone-action:before {
  content: $avricon-phone-action;
}

.avricon-plant-leaf:before {
  content: $avricon-plant-leaf;
}

.avricon-political-globe:before {
  content: $avricon-political-globe;
}

.avricon-power-button:before {
  content: $avricon-power-button;
}

.avricon-press:before {
  content: $avricon-press;
}

.avricon-printer-document:before {
  content: $avricon-printer-document;
}

.avricon-question-circle:before {
  content: $avricon-question-circle;
}

.avricon-real-estate-skyscraper:before {
  content: $avricon-real-estate-skyscraper;
}

.avricon-redo-arrow:before {
  content: $avricon-redo-arrow;
}

.avricon-refresh-arrow:before {
  content: $avricon-refresh-arrow;
}

.avricon-remove-shield:before {
  content: $avricon-remove-shield;
}

.avricon-replay-repeat-arrow:before {
  content: $avricon-replay-repeat-arrow;
}

.avricon-respondsive-design:before {
  content: $avricon-respondsive-design;
}

.avricon-route-sign-parking-area:before {
  content: $avricon-route-sign-parking-area;
}

.avricon-route-sign-parking-not-allow:before {
  content: $avricon-route-sign-parking-not-allow;
}

.avricon-route-sign-staight:before {
  content: $avricon-route-sign-staight;
}

.avricon-route-sign-traffic-light-ahead:before {
  content: $avricon-route-sign-traffic-light-ahead;
}

.avricon-route-sign-u-turn-ahead:before {
  content: $avricon-route-sign-u-turn-ahead;
}

.avricon-school-graduate:before {
  content: $avricon-school-graduate;
}

.avricon-settings-cog-1:before {
  content: $avricon-settings-cog-1;
}

.avricon-settings-slider-horizontal-1:before {
  content: $avricon-settings-slider-horizontal-1;
}

.avricon-share-box:before {
  content: $avricon-share-box;
}

.avricon-shield-check:before {
  content: $avricon-shield-check;
}

.avricon-shield-question:before {
  content: $avricon-shield-question;
}

.avricon-shield-user:before {
  content: $avricon-shield-user;
}

.avricon-shiftmove:before {
  content: $avricon-shiftmove;
}

.avricon-shop:before {
  content: $avricon-shop;
}

.avricon-shopping-cart-full:before {
  content: $avricon-shopping-cart-full;
}

.avricon-shopping-cart:before {
  content: $avricon-shopping-cart;
}

.avricon-shrink-circle:before {
  content: $avricon-shrink-circle;
}

.avricon-shrink-diagonal:before {
  content: $avricon-shrink-diagonal;
}

.avricon-shuffle-play-arrow:before {
  content: $avricon-shuffle-play-arrow;
}

.avricon-speed-guage-display:before {
  content: $avricon-speed-guage-display;
}

.avricon-star-circle:before {
  content: $avricon-star-circle;
}

.avricon-subtract-small:before {
  content: $avricon-subtract-small;
}

.avricon-synchronize-arrow-time:before {
  content: $avricon-synchronize-arrow-time;
}

.avricon-synchronize-arrows:before {
  content: $avricon-synchronize-arrows;
}

.avricon-tag-barcode-search-check:before {
  content: $avricon-tag-barcode-search-check;
}

.avricon-tag-currency-euro:before {
  content: $avricon-tag-currency-euro;
}

.avricon-tag-double:before {
  content: $avricon-tag-double;
}

.avricon-task-list-check:before {
  content: $avricon-task-list-check;
}

.avricon-task-list:before {
  content: $avricon-task-list;
}

.avricon-tool-box:before {
  content: $avricon-tool-box;
}

.avricon-tool-traffic-cone:before {
  content: $avricon-tool-traffic-cone;
}

.avricon-tool-wrench-hand:before {
  content: $avricon-tool-wrench-hand;
}

.avricon-toolbox:before {
  content: $avricon-toolbox;
}

.avricon-travel-luggage:before {
  content: $avricon-travel-luggage;
}

.avricon-trip-category:before {
  content: $avricon-trip-category;
}

.avricon-truck-caravan-house-car:before {
  content: $avricon-truck-caravan-house-car;
}

.avricon-truck-liquid-container:before {
  content: $avricon-truck-liquid-container;
}

.avricon-undo-arrow:before {
  content: $avricon-undo-arrow;
}

.avricon-user-business-man:before {
  content: $avricon-user-business-man;
}

.avricon-user-geometric-action-add-disabled:before {
  content: $avricon-user-geometric-action-add-disabled;
}

.avricon-user-geometric-action-add:before {
  content: $avricon-user-geometric-action-add;
}

.avricon-user-geometric-action-check-approve:before {
  content: $avricon-user-geometric-action-check-approve;
}

.avricon-user-geometric-action-mail:before {
  content: $avricon-user-geometric-action-mail;
}

.avricon-user-geometric-action-phone:before {
  content: $avricon-user-geometric-action-phone;
}

.avricon-user-geometric-action-restrict:before {
  content: $avricon-user-geometric-action-restrict;
}

.avricon-user-geometric-action:before {
  content: $avricon-user-geometric-action;
}

.avricon-user-multiple-minus:before {
  content: $avricon-user-multiple-minus;
}

.avricon-user-multiple-plus:before {
  content: $avricon-user-multiple-plus;
}

.avricon-user-multiple:before {
  content: $avricon-user-multiple;
}

.avricon-user-profile-circle:before {
  content: $avricon-user-profile-circle;
}

.avricon-vehicle-burning-hot:before {
  content: $avricon-vehicle-burning-hot;
}

.avricon-vehicle-car-personal-disabled:before {
  content: $avricon-vehicle-car-personal-disabled;
}

.avricon-vehicle-car-personal-emphasis:before {
  content: $avricon-vehicle-car-personal-emphasis;
}

.avricon-vehicle-car-personal-side:before {
  content: $avricon-vehicle-car-personal-side;
}

.avricon-vehicle-car-personal:before {
  content: $avricon-vehicle-car-personal;
}

.avricon-vehicle-dollar:before {
  content: $avricon-vehicle-dollar;
}

.avricon-vehicle-garage:before {
  content: $avricon-vehicle-garage;
}

.avricon-vehicle-lifter:before {
  content: $avricon-vehicle-lifter;
}

.avricon-vehicle-network:before {
  content: $avricon-vehicle-network;
}

.avricon-vehicle-person:before {
  content: $avricon-vehicle-person;
}

.avricon-vehicle-plus:before {
  content: $avricon-vehicle-plus;
}

.avricon-vehicle-protection-shield:before {
  content: $avricon-vehicle-protection-shield;
}

.avricon-vehicle-questionmark:before {
  content: $avricon-vehicle-questionmark;
}

.avricon-vehicle-restrict:before {
  content: $avricon-vehicle-restrict;
}

.avricon-vehicle-search:before {
  content: $avricon-vehicle-search;
}

.avricon-vehicle-tag:before {
  content: $avricon-vehicle-tag;
}

.avricon-vehicle-tractor:before {
  content: $avricon-vehicle-tractor;
}

.avricon-vehicle-transporter:before {
  content: $avricon-vehicle-transporter;
}

.avricon-vehicle-washing-service:before {
  content: $avricon-vehicle-washing-service;
}

.avricon-vehicle-wrench-tool:before {
  content: $avricon-vehicle-wrench-tool;
}

.avricon-video-camera:before {
  content: $avricon-video-camera;
}

.avricon-vimcar:before {
  content: $avricon-vimcar;
}

.avricon-water-protection-drop:before {
  content: $avricon-water-protection-drop;
}

.avricon-weather-sun:before {
  content: $avricon-weather-sun;
}

.avricon-wifi-network-disable-restrict:before {
  content: $avricon-wifi-network-disable-restrict;
}

.avricon-wifi-network:before {
  content: $avricon-wifi-network;
}

.avricon-wrench-hammer:before {
  content: $avricon-wrench-hammer;
}

.avricon-zigma:before {
  content: $avricon-zigma;
}

.avricon-zigzag-merge-arrow:before {
  content: $avricon-zigzag-merge-arrow;
}

.avricon-zigzag-split-arrow:before {
  content: $avricon-zigzag-split-arrow;
}

.avricon-callcenter-help:before {
  content: $avricon-callcenter-help;
}

.avricon-diamond:before {
  content: $avricon-diamond;
}

.avricon-email-send:before {
  content: $avricon-email-send;
}

.avricon-link:before {
  content: $avricon-link;
}

.avricon-open-book:before {
  content: $avricon-open-book;
}

.avricon-stacked-bar-chart:before {
  content: $avricon-stacked-bar-chart;
}

.avricon-rocket:before {
  content: $avricon-rocket;
}

.avricon-speeding:before {
  content: $avricon-speeding;
}

.avricon-three-dots:before {
  content: $avricon-three-dots;
}

.avricon-parking-forbidden:before {
  content: $avricon-parking-forbidden;
}

.avricon-red-light:before {
  content: $avricon-red-light;
}

.avricon-workshop:before {
  content: $avricon-workshop;
}

.avricon-unlink-item:before {
  content: $avricon-unlink-item;
}

.avricon-store:before {
  content: $avricon-store;
}

.avricon-damage-cone:before {
  content: $avricon-damage-cone;
}

.avricon-fuel-card:before {
  content: $avricon-fuel-card;
}

.avricon-table-view:before {
  content: $avricon-table-view;
}

.avricon-group-view:before {
  content: $avricon-group-view;
}

.avricon-cloud:before {
  content: $avricon-cloud;
}

.avricon-water-drop:before {
  content: $avricon-water-drop;
}

.avricon-emissions:before {
  content: $avricon-emissions;
}

.avricon-arrow-back-long:before {
  content: $avricon-arrow-back-long;
}

.avricon-approved:before {
  content: $avricon-approved;
}

.avricon-warning:before {
  content: $avricon-warning;
}

.avricon-dollar:before {
  content: $avricon-dollar;
}

.avricon-file-dollar-search:before {
  content: $avricon-file-dollar-search;
}

.avricon-ready:before {
  content: $avricon-ready;
}

.avricon-leased-car:before {
  content: $avricon-leased-car;
}

.avricon-car-block:before {
  content: $avricon-car-block;
}

.avricon-redo:before {
  content: $avricon-redo;
}

.avricon-build-up:before {
  content: $avricon-build-up;
}

.avricon-folder-add:before {
  content: $avricon-folder-add;
}

.avricon-move:before {
  content: $avricon-move;
}

.avricon-control-pause:before {
  content: $avricon-control-pause;
}

.avricon-speaker-volume-high:before {
  content: $avricon-speaker-volume-high;
}

.avricon-timer:before {
  content: $avricon-timer;
}

.avricon-external-link:before {
  content: $avricon-external-link;
}

.avricon-subtract-circle:before {
  content: $avricon-subtract-circle;
}

.avricon-arrow-sync:before {
  content: $avricon-arrow-sync;
}

.avricon-contract:before {
  content: $avricon-contract;
}

.avricon-flash:before {
  content: $avricon-flash;
}

.avricon-columns:before {
  content: $avricon-columns;
}

.avricon-notifications:before {
  content: $avricon-notifications;
}

.avricon-remove:before {
  content: $avricon-remove;
}

.avricon-costs:before {
  content: $avricon-costs;
}

.avricon-invoice-import:before {
  content: $avricon-invoice-import;
}

.avricon-task:before {
  content: $avricon-task;
}

.avricon-settings:before {
  content: $avricon-settings;
}

.avricon-help:before {
  content: $avricon-help;
}

.avricon-driver-person:before {
  content: $avricon-driver-person;
}

.avricon-home:before {
  content: $avricon-home;
}

.avricon-chevron-left:before {
  content: $avricon-chevron-left;
}

.avricon-chevron-right:before {
  content: $avricon-chevron-right;
}

.avricon-chevron-up:before {
  content: $avricon-chevron-up;
}

.avricon-chevron-down:before {
  content: $avricon-chevron-down;
}

.avricon-banking-office-hour:before {
  content: $avricon-banking-office-hour;
}

.avricon-add:before {
  content: $avricon-add;
}

.avricon-address-book:before {
  content: $avricon-address-book;
}

.avricon-clock:before {
  content: $avricon-clock;
}

.avricon-alarm-clock:before {
  content: $avricon-alarm-clock;
}

.avricon-clock-fire:before {
  content: $avricon-clock-fire;
}

.avricon-graduation-hat:before {
  content: $avricon-graduation-hat;
}

.avricon-alert-circle:before {
  content: $avricon-alert-circle;
}

.avricon-alert-document:before {
  content: $avricon-alert-document;
}

.avricon-arrow-enter-right:before {
  content: $avricon-arrow-enter-right;
}

.avricon-arrow-left:before {
  content: $avricon-arrow-left;
}

.avricon-arrow-right:before {
  content: $avricon-arrow-right;
}

.avricon-arrow-down:before {
  content: $avricon-arrow-down;
}

.avricon-arrow-up:before {
  content: $avricon-arrow-up;
}

.avricon-bin:before {
  content: $avricon-bin;
}

.avricon-bus:before {
  content: $avricon-bus;
}

.avricon-calendar:before {
  content: $avricon-calendar;
}

.avricon-calendar-blank:before {
  content: $avricon-calendar-blank;
}

.avricon-calendar-dollar:before {
  content: $avricon-calendar-dollar;
}

.avricon-camera:before {
  content: $avricon-camera;
}

.avricon-car:before {
  content: $avricon-car;
}

.avricon-car-signal:before {
  content: $avricon-car-signal;
}

.avricon-card:before {
  content: $avricon-card;
}

.avricon-cart:before {
  content: $avricon-cart;
}

.avricon-chat-bubble:before {
  content: $avricon-chat-bubble;
}

.avricon-chat-bubble-timeout:before {
  content: $avricon-chat-bubble-timeout;
}

.avricon-check:before {
  content: $avricon-check;
}

.avricon-check-circle:before {
  content: $avricon-check-circle;
}

.avricon-check-multiple:before {
  content: $avricon-check-multiple;
}

.avricon-check-shield:before {
  content: $avricon-check-shield;
}

.avricon-circle-cash:before {
  content: $avricon-circle-cash;
}

.avricon-close-shield:before {
  content: $avricon-close-shield;
}

.avricon-cloud-progress:before {
  content: $avricon-cloud-progress;
}

.avricon-coffee:before {
  content: $avricon-coffee;
}

.avricon-cog:before {
  content: $avricon-cog;
}

.avricon-company:before {
  content: $avricon-company;
}

.avricon-cost-center:before {
  content: $avricon-cost-center;
}

.avricon-damage:before {
  content: $avricon-damage;
}

.avricon-devices:before {
  content: $avricon-devices;
}

.avricon-download:before {
  content: $avricon-download;
}

.avricon-drag-handle:before {
  content: $avricon-drag-handle;
}

.avricon-driver:before {
  content: $avricon-driver;
}

.avricon-envelope:before {
  content: $avricon-envelope;
}

.avricon-excavator:before {
  content: $avricon-excavator;
}

.avricon-excel:before {
  content: $avricon-excel;
}

.avricon-eye:before {
  content: $avricon-eye;
}

.avricon-file:before {
  content: $avricon-file;
}

.avricon-file-copy:before {
  content: $avricon-file-copy;
}

.avricon-file-edit:before {
  content: $avricon-file-edit;
}

.avricon-file-image:before {
  content: $avricon-file-image;
}

.avricon-file-incomplete:before {
  content: $avricon-file-incomplete;
}

.avricon-file-pdf:before {
  content: $avricon-file-pdf;
}

.avricon-file-refresh:before {
  content: $avricon-file-refresh;
}

.avricon-file-star:before {
  content: $avricon-file-star;
}

.avricon-financing:before {
  content: $avricon-financing;
}

.avricon-flag:before {
  content: $avricon-flag;
}

.avricon-flip-horizontally:before {
  content: $avricon-flip-horizontally;
}

.avricon-flip-vertically:before {
  content: $avricon-flip-vertically;
}

.avricon-folder:before {
  content: $avricon-folder;
}

.avricon-folder-lock:before {
  content: $avricon-folder-lock;
}

.avricon-folder-move:before {
  content: $avricon-folder-move;
}

.avricon-forklift:before {
  content: $avricon-forklift;
}

.avricon-fuel:before {
  content: $avricon-fuel;
}

.avricon-gauge:before {
  content: $avricon-gauge;
}

.avricon-gift:before {
  content: $avricon-gift;
}

.avricon-hash:before {
  content: $avricon-hash;
}

.avricon-heart:before {
  content: $avricon-heart;
}

.avricon-hide:before {
  content: $avricon-hide;
}

.avricon-hive:before {
  content: $avricon-hive;
}

.avricon-hourglass:before {
  content: $avricon-hourglass;
}

.avricon-inbox:before {
  content: $avricon-inbox;
}

.avricon-info:before {
  content: $avricon-info;
}

.avricon-insurance:before {
  content: $avricon-insurance;
}

.avricon-invoice:before {
  content: $avricon-invoice;
}

.avricon-license:before {
  content: $avricon-license;
}

.avricon-list-bullets:before {
  content: $avricon-list-bullets;
}

.avricon-lock:before {
  content: $avricon-lock;
}

.avricon-lock-open:before {
  content: $avricon-lock-open;
}

.avricon-login:before {
  content: $avricon-login;
}

.avricon-logout:before {
  content: $avricon-logout;
}

.avricon-maintenance:before {
  content: $avricon-maintenance;
}

.avricon-megaphone:before {
  content: $avricon-megaphone;
}

.avricon-mileage:before {
  content: $avricon-mileage;
}

.avricon-motorcycle:before {
  content: $avricon-motorcycle;
}

.avricon-paperclip:before {
  content: $avricon-paperclip;
}

.avricon-pause-circle:before {
  content: $avricon-pause-circle;
}

.avricon-pencil:before {
  content: $avricon-pencil;
}

.avricon-phone:before {
  content: $avricon-phone;
}

.avricon-phone-off:before {
  content: $avricon-phone-off;
}

.avricon-printer:before {
  content: $avricon-printer;
}

.avricon-question-shield:before {
  content: $avricon-question-shield;
}

.avricon-refresh:before {
  content: $avricon-refresh;
}

.avricon-remove-circle:before {
  content: $avricon-remove-circle;
}

.avricon-rotate-clockwise:before {
  content: $avricon-rotate-clockwise;
}

.avricon-rotate-counter-clockwise:before {
  content: $avricon-rotate-counter-clockwise;
}

.avricon-salesrep:before {
  content: $avricon-salesrep;
}

.avricon-search:before {
  content: $avricon-search;
}

.avricon-share-link:before {
  content: $avricon-share-link;
}

.avricon-share-time:before {
  content: $avricon-share-time;
}

.avricon-show-more-vertical:before {
  content: $avricon-show-more-vertical;
}

.avricon-shrink:before {
  content: $avricon-shrink;
}

.avricon-smartphone:before {
  content: $avricon-smartphone;
}

.avricon-special:before {
  content: $avricon-special;
}

.avricon-string:before {
  content: $avricon-string;
}

.avricon-sun:before {
  content: $avricon-sun;
}

.avricon-tractor:before {
  content: $avricon-tractor;
}

.avricon-trailer:before {
  content: $avricon-trailer;
}

.avricon-transfer:before {
  content: $avricon-transfer;
}

.avricon-transporter:before {
  content: $avricon-transporter;
}

.avricon-trolley-box:before {
  content: $avricon-trolley-box;
}

.avricon-truck:before {
  content: $avricon-truck;
}

.avricon-unknown:before {
  content: $avricon-unknown;
}

.avricon-upload:before {
  content: $avricon-upload;
}

.avricon-user:before {
  content: $avricon-user;
}

.avricon-user-block:before {
  content: $avricon-user-block;
}

.avricon-user-chat:before {
  content: $avricon-user-chat;
}

.avricon-user-group:before {
  content: $avricon-user-group;
}

.avricon-vehicle:before {
  content: $avricon-vehicle;
}

.avricon-vehicle-snooze:before {
  content: $avricon-vehicle-snooze;
}

.avricon-vehicles:before {
  content: $avricon-vehicles;
}

.avricon-virtual:before {
  content: $avricon-virtual;
}

.avricon-vote-star-circle:before {
  content: $avricon-vote-star-circle;
}

.avricon-window-list:before {
  content: $avricon-window-list;
}

.avricon-wrench:before {
  content: $avricon-wrench;
}

.avricon-zoom-in:before {
  content: $avricon-zoom-in;
}

.avricon-zoom-out:before {
  content: $avricon-zoom-out;
}

.avricon-trend-up:before {
  content: $avricon-trend-up;
}

.avricon-trend-down:before {
  content: $avricon-trend-down;
}

.avricon-user-shield:before {
  content: $avricon-user-shield;
}

.avricon-plus-circle:before {
  content: $avricon-plus-circle;
}

.avricon-medical-shield:before {
  content: $avricon-medical-shield;
}

.avricon-alarm-disabled:before {
  content: $avricon-alarm-disabled;
}

.avricon-arrow-left-end:before {
  content: $avricon-arrow-left-end;
}

.avricon-arrow-right-end:before {
  content: $avricon-arrow-right-end;
}

.avricon-play-circle:before {
  content: $avricon-play-circle;
}

.avricon-chart-pie:before {
  content: $avricon-chart-pie;
}

.avricon-power:before {
  content: $avricon-power;
}

.avricon-arrow-up-circle:before {
  content: $avricon-arrow-up-circle;
}

.avricon-file-arrow-up:before {
  content: $avricon-file-arrow-up;
}

.avricon-filter:before {
  content: $avricon-filter;
}

.avricon-allianz:before {
  content: $avricon-allianz;
}

.avricon-money-note:before {
  content: $avricon-money-note;
}

.avricon-globe:before {
  content: $avricon-globe;
}

.avricon-circle-percent:before {
  content: $avricon-circle-percent;
}

.avricon-icon-yes-no:before {
  content: $avricon-icon-yes-no;
}

.avricon-nature-leaf:before {
  content: $avricon-nature-leaf;
}

.avricon-dollar-circle:before {
  content: $avricon-dollar-circle;
}

.avricon-location-pin:before {
  content: $avricon-location-pin;
}

.avricon-speedometer:before {
  content: $avricon-speedometer;
}

.avricon-weight:before {
  content: $avricon-weight;
}

.avricon-at-symbol:before {
  content: $avricon-at-symbol;
}

.avricon-credit-card:before {
  content: $avricon-credit-card;
}

.avricon-tags:before {
  content: $avricon-tags;
}

.avricon-idea:before {
  content: $avricon-idea;
}

.avricon-tilde-circle:before {
  content: $avricon-tilde-circle;
}

.avricon-arrow-forward-long:before {
  content: $avricon-arrow-forward-long;
}

.avricon-avrios:before {
  content: $avricon-avrios;
}

.avricon-pricing-packages:before {
  content: $avricon-pricing-packages;
}

.avricon-star:before {
  content: $avricon-star;
}

.avricon-delete:before {
  content: $avricon-delete;
}

.avricon-compass:before {
  content: $avricon-compass;
}

.avricon-expand:before {
  content: $avricon-expand;
}

.avricon-automatic-import:before {
  content: $avricon-automatic-import;
}

.avricon-manual-import:before {
  content: $avricon-manual-import;
}

.avricon-piggy-bank:before {
  content: $avricon-piggy-bank;
}

.avricon-tire:before {
  content: $avricon-tire;
}

.avricon-swap-body:before {
  content: $avricon-swap-body;
}

.avricon-chevron-left-s:before {
  content: $avricon-chevron-left-s;
}

.avricon-chevron-up-s:before {
  content: $avricon-chevron-up-s;
}

.avricon-chevron-down-s:before {
  content: $avricon-chevron-down-s;
}

.avricon-chevron-right-s:before {
  content: $avricon-chevron-right-s;
}