.static-value,
.property-list .property-value {
  .editable,
  a.editable {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: $avr-color-gray-10;
    padding: {
      top: $form-label-padding-vertical;
      bottom: $form-label-padding-vertical;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: -$avr-space-xs;
      bottom: 0;
      left: -$avr-space-xs;
      border: 1px solid transparent;
      border-radius: $avr-border-radius-50;
      transition: border-color $avr-animation-duration;
    }

    avr-icon {
      visibility: hidden;
    }

    span.edit,
    span.locked {
      visibility: hidden;
      margin-left: 5px;
      color: $avr-color-blue-40;
    }

    &:hover {
      avr-icon {
        visibility: initial;
        color: $avr-color-blue-40;
      }

      &:before {
        border-color: rgba($avr-color-blue-30, 0.3);
      }

      span.edit,
      span.locked {
        visibility: visible;
      }
    }

    &.editable-empty {
      &,
      &:hover,
      &:focus,
      pre {
        @include text-empty;
      }

      span.edit,
      span.locked {
        color: $avr-color-gray-50;
      }
    }
  }
}
