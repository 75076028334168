@use 'sass:math';
@use '@shiftmove/design-system-toolkit/public/tokens/scss/ShiftmoveDesignSystemColor' as ShiftmoveColor;
@use '@shiftmove/design-system-toolkit/public/tokens/scss/ShiftmoveDesignSystemSpacing' as ShiftmoveSpacing;
@use '@shiftmove/design-system-toolkit/public/tokens/scss/ShiftmoveDesignSystemBorder' as ShiftmoveBorder;
@use '@shiftmove/design-system-toolkit/public/tokens/scss/ShiftmoveDesignSystemFont' as ShiftmoveFont;

// icon sizes
$avr-icon-size-xs:            14px;
$avr-icon-size-s:             16px;
$avr-icon-size-sm:            18px;
$avr-icon-size-m:             24px;
$avr-icon-size-l:             32px;
$avr-icon-size-xl:            48px;

// font definitions
$avr-font-size-xxl:               28px;
$avr-font-size-xl:                18px;
$avr-font-size-large:             16px;
$avr-font-size-base:              14px;
$avr-font-size-smallish:          13px;
$avr-font-size-small:             12px;
$avr-font-size-tiny:              11px;
$avr-font-weight-normal:          ShiftmoveFont.$shiftmove-font-weight-plain-default;
$avr-font-weight-semibold:        ShiftmoveFont.$shiftmove-font-weight-plain-emphasis;
$avr-font-weight-bold:            700;
$avr-font-family-plain:           ShiftmoveFont.$shiftmove-font-family-plain, sans-serif;
$avr-font-family-brand-default:   ShiftmoveFont.$shiftmove-font-family-brand, sans-serif;
$avr-font-weight-brand-emphasis:  ShiftmoveFont.$shiftmove-font-weight-brand-emphasis;

$avr-line-height-default: 1.5em;

// bootstrap font definitions
$font-family-sans-serif:      $avr-font-family-plain;
$font-family-serif:           $avr-font-family-plain;

// bootstrap iconography
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

// spacing
$avr-space-base:              ShiftmoveSpacing.$shiftmove-spacing-50;
$avr-space-xxs:               ShiftmoveSpacing.$shiftmove-spacing-25; // 2px
$avr-space-xs:                ShiftmoveSpacing.$shiftmove-spacing-50; // 4px
$avr-space-s:                 ShiftmoveSpacing.$shiftmove-spacing-100; // 8px
$avr-space-m:                 ShiftmoveSpacing.$shiftmove-spacing-200; // 16px
$avr-space-l:                 ShiftmoveSpacing.$shiftmove-spacing-300; // 24px
$avr-space-xl:                ShiftmoveSpacing.$shiftmove-spacing-400; // 32px
$avr-space-xxl:               ShiftmoveSpacing.$shiftmove-spacing-600; // 48px
$avr-space-xxxl:              ShiftmoveSpacing.$shiftmove-spacing-800; // 64px
$avr-space-xxxxl:             ShiftmoveSpacing.$shiftmove-spacing-1600; // 128px
$shiftmove-spacing-150:       ShiftmoveSpacing.$shiftmove-spacing-150; // 12px

// colours
$avr-color-blue-0:             ShiftmoveColor.$shiftmove-color-brand-0;
$avr-color-blue-10:            ShiftmoveColor.$shiftmove-color-brand-10;
$avr-color-blue-20:            ShiftmoveColor.$shiftmove-color-brand-20;
$avr-color-blue-30:            ShiftmoveColor.$shiftmove-color-brand-30;
$avr-color-blue-40:            ShiftmoveColor.$shiftmove-color-brand-40;
$avr-color-blue-50:            ShiftmoveColor.$shiftmove-color-brand-50;
$avr-color-blue-60:            ShiftmoveColor.$shiftmove-color-brand-60;
$avr-color-blue-70:            ShiftmoveColor.$shiftmove-color-brand-70;
$avr-color-blue-80:            ShiftmoveColor.$shiftmove-color-brand-80;
$avr-color-blue-90:            ShiftmoveColor.$shiftmove-color-brand-90;
$avr-color-blue-95:            ShiftmoveColor.$shiftmove-color-brand-95;
$avr-color-blue-98:            ShiftmoveColor.$shiftmove-color-brand-98;
$avr-color-blue-100:           ShiftmoveColor.$shiftmove-color-brand-100;

$avr-color-green-0:            ShiftmoveColor.$shiftmove-color-success-0;
$avr-color-green-10:           ShiftmoveColor.$shiftmove-color-success-10;
$avr-color-green-20:           ShiftmoveColor.$shiftmove-color-success-20;
$avr-color-green-30:           ShiftmoveColor.$shiftmove-color-success-30;
$avr-color-green-40:           ShiftmoveColor.$shiftmove-color-success-40;
$avr-color-green-50:           ShiftmoveColor.$shiftmove-color-success-50;
$avr-color-green-60:           ShiftmoveColor.$shiftmove-color-success-60;
$avr-color-green-70:           ShiftmoveColor.$shiftmove-color-success-70;
$avr-color-green-80:           ShiftmoveColor.$shiftmove-color-success-80;
$avr-color-green-90:           ShiftmoveColor.$shiftmove-color-success-90;
$avr-color-green-95:           ShiftmoveColor.$shiftmove-color-success-95;
$avr-color-green-98:           ShiftmoveColor.$shiftmove-color-success-98;
$avr-color-green-100:          ShiftmoveColor.$shiftmove-color-success-100;

$avr-color-red-0:              ShiftmoveColor.$shiftmove-color-error-0;
$avr-color-red-10:             ShiftmoveColor.$shiftmove-color-error-10;
$avr-color-red-20:             ShiftmoveColor.$shiftmove-color-error-20;
$avr-color-red-30:             ShiftmoveColor.$shiftmove-color-error-30;
$avr-color-red-40:             ShiftmoveColor.$shiftmove-color-error-40;
$avr-color-red-50:             ShiftmoveColor.$shiftmove-color-error-50;
$avr-color-red-60:             ShiftmoveColor.$shiftmove-color-error-60;
$avr-color-red-70:             ShiftmoveColor.$shiftmove-color-error-70;
$avr-color-red-80:             ShiftmoveColor.$shiftmove-color-error-80;
$avr-color-red-90:             ShiftmoveColor.$shiftmove-color-error-90;
$avr-color-red-95:             ShiftmoveColor.$shiftmove-color-error-95;
$avr-color-red-98:             ShiftmoveColor.$shiftmove-color-error-98;
$avr-color-red-100:            ShiftmoveColor.$shiftmove-color-error-100;

$avr-color-yellow-0:           ShiftmoveColor.$shiftmove-color-warning-0;
$avr-color-yellow-98:          ShiftmoveColor.$shiftmove-color-warning-10;
$avr-color-yellow-20:          ShiftmoveColor.$shiftmove-color-warning-20;
$avr-color-yellow-30:          ShiftmoveColor.$shiftmove-color-warning-30;
$avr-color-yellow-40:          ShiftmoveColor.$shiftmove-color-warning-40;
$avr-color-yellow-50:          ShiftmoveColor.$shiftmove-color-warning-50;
$avr-color-yellow-60:          ShiftmoveColor.$shiftmove-color-warning-60;
$avr-color-yellow-70:          ShiftmoveColor.$shiftmove-color-warning-70;
$avr-color-yellow-80:          ShiftmoveColor.$shiftmove-color-warning-80;
$avr-color-yellow-90:          ShiftmoveColor.$shiftmove-color-warning-90;
$avr-color-yellow-95:          ShiftmoveColor.$shiftmove-color-warning-95;
$avr-color-yellow-98:          ShiftmoveColor.$shiftmove-color-warning-98;
$avr-color-yellow-100:         ShiftmoveColor.$shiftmove-color-warning-100;

$avr-color-gray-0:             ShiftmoveColor.$shiftmove-color-neutral-0;
$avr-color-gray-10:            ShiftmoveColor.$shiftmove-color-neutral-10;
$avr-color-gray-20:            ShiftmoveColor.$shiftmove-color-neutral-20;
$avr-color-gray-30:            ShiftmoveColor.$shiftmove-color-neutral-30;
$avr-color-gray-40:            ShiftmoveColor.$shiftmove-color-neutral-40;
$avr-color-gray-50:            ShiftmoveColor.$shiftmove-color-neutral-50;
$avr-color-gray-60:            ShiftmoveColor.$shiftmove-color-neutral-60;
$avr-color-gray-70:            ShiftmoveColor.$shiftmove-color-neutral-70;
$avr-color-gray-80:            ShiftmoveColor.$shiftmove-color-neutral-80;
$avr-color-gray-90:            ShiftmoveColor.$shiftmove-color-neutral-90;
$avr-color-gray-95:            ShiftmoveColor.$shiftmove-color-neutral-95;
$avr-color-gray-98:            ShiftmoveColor.$shiftmove-color-neutral-98;
$avr-color-gray-100:           ShiftmoveColor.$shiftmove-color-neutral-100;

$avr-color-purple-0:           ShiftmoveColor.$shiftmove-color-accent-primary-0;
$avr-color-purple-10:          ShiftmoveColor.$shiftmove-color-accent-primary-10;
$avr-color-purple-20:          ShiftmoveColor.$shiftmove-color-accent-primary-20;
$avr-color-purple-30:          ShiftmoveColor.$shiftmove-color-accent-primary-30;
$avr-color-purple-40:          ShiftmoveColor.$shiftmove-color-accent-primary-40;
$avr-color-purple-50:          ShiftmoveColor.$shiftmove-color-accent-primary-50;
$avr-color-purple-60:          ShiftmoveColor.$shiftmove-color-accent-primary-60;
$avr-color-purple-70:          ShiftmoveColor.$shiftmove-color-accent-primary-70;
$avr-color-purple-80:          ShiftmoveColor.$shiftmove-color-accent-primary-80;
$avr-color-purple-90:          ShiftmoveColor.$shiftmove-color-accent-primary-90;
$avr-color-purple-95:          ShiftmoveColor.$shiftmove-color-accent-primary-95;
$avr-color-purple-98:          ShiftmoveColor.$shiftmove-color-accent-primary-98;
$avr-color-purple-100:         ShiftmoveColor.$shiftmove-color-accent-primary-100;

$avr-color-brand-black:        $avr-color-gray-10;
$avr-color-black:              #000;
$avr-color-white:              #fff;

$avr-color-white-alpha-10:     rgba($avr-color-white, 0.1);
$avr-color-white-alpha-35:     rgba($avr-color-white, 0.35);
$avr-color-white-alpha-50:     rgba($avr-color-white, 0.5);
$avr-color-white-alpha-60:     rgba($avr-color-white, 0.6);
$avr-color-white-alpha-70:     rgba($avr-color-white, 0.7);
$avr-color-white-alpha-80:     rgba($avr-color-white, 0.8);
$avr-color-white-alpha-95:     rgba($avr-color-white, 0.96);

$avr-color-black-alpha-05:     rgba($avr-color-black, 0.05);
$avr-color-black-alpha-10:     rgba($avr-color-black, 0.1);
$avr-color-black-alpha-15:     rgba($avr-color-black, 0.15);
$avr-color-black-alpha-20:     rgba($avr-color-black, 0.2);
$avr-color-black-alpha-25:     rgba($avr-color-black, 0.25);
$avr-color-black-alpha-40:     rgba($avr-color-black, 0.4);
$avr-color-black-alpha-50:     rgba($avr-color-black, 0.5);
$avr-color-black-alpha-80:     rgba($avr-color-black, 0.8);

// redefining and overriding bootstrap definitions
$link-color:                   $avr-color-blue-40;
$link-hover-color:             $avr-color-blue-50;
$link-hover-decoration:        none;

$text-color:                  $avr-color-gray-10;
$text-muted:                  $avr-color-gray-50;

$brand-primary:               $avr-color-blue-50;

$brand-success:               $avr-color-green-40;
$brand-info:                  $avr-color-blue-50;
$brand-warning:               $avr-color-yellow-30;
$brand-danger:                $avr-color-red-50;

$state-success-text:          $avr-color-green-30;
$state-success-bg:            $avr-color-green-90;
$state-success-border:        $avr-color-green-70;

$state-info-text:             $avr-color-blue-30;
$state-info-bg:               $avr-color-blue-90;
$state-info-border:           $avr-color-blue-70;

$state-warning-text:          $avr-color-yellow-30;
$state-warning-bg:            $avr-color-yellow-90;
$state-warning-border:        $avr-color-yellow-70;

$state-danger-text:           $avr-color-red-30;
$state-danger-bg:             $avr-color-red-90;
$state-danger-border:         $avr-color-red-70;

$panel-primary-bg:            $brand-primary;
$body-bg:                     $avr-color-white;
$nav-link-hover-bg:           $avr-color-gray-95;
$nav-tabs-link-hover-border-color: $nav-link-hover-bg;

// Border Width
$avr-border-width-100:          ShiftmoveBorder.$shiftmove-border-width-100; // 1px
$avr-border-width-200:          ShiftmoveBorder.$shiftmove-border-width-200; // 2px

// Border Line
$avr-border-width-100-gray-20:  ShiftmoveBorder.$shiftmove-border-width-100 solid $avr-color-gray-95;
$avr-border-width-100-gray-30:  ShiftmoveBorder.$shiftmove-border-width-100 solid $avr-color-gray-90;
$avr-border-width-100-gray-40:  ShiftmoveBorder.$shiftmove-border-width-100 solid $avr-color-gray-80;
$avr-border-width-100-gray-50:  ShiftmoveBorder.$shiftmove-border-width-100 solid $avr-color-gray-50;
$avr-border-width-200-gray-30:  ShiftmoveBorder.$shiftmove-border-width-200 solid $avr-color-gray-90;
$avr-border-width-200-gray-50:  ShiftmoveBorder.$shiftmove-border-width-200 solid $avr-color-gray-50;

// Border radius
$avr-border-radius-25:          ShiftmoveBorder.$shiftmove-border-radius-25; // 2px
$avr-border-radius-50:          ShiftmoveBorder.$shiftmove-border-radius-50; // 4px
$avr-border-radius-100:         ShiftmoveBorder.$shiftmove-border-radius-100; // 8px
$avr-border-radius-200:         ShiftmoveBorder.$shiftmove-border-radius-200; // 16px
$avr-border-radius-400:         ShiftmoveBorder.$shiftmove-border-radius-400; // 32px
$avr-border-radius-circle:      ShiftmoveBorder.$shiftmove-border-radius-circle; // 9999px

// Inputs
$input-color-placeholder:     $avr-color-gray-80;
$input-color:                 $avr-color-brand-black;
$input-border:                $avr-color-gray-80;
$input-border-focus:          $avr-color-blue-70;
$input-bg-disabled:           $avr-color-gray-98;
$input-border-disabled:       $avr-color-blue-98;
$input-bg-focus:              $avr-color-blue-98;
$input-danger-border:         $avr-color-red-70;
$input-danger-border-focus:   $avr-color-red-50;
$input-danger-bg:             $avr-color-red-98;
$input-group-addon-bg:        $avr-color-white;

// navbar
$navbar-inverse-bg:               $avr-color-blue-50;
$navbar-inverse-link-active-bg:   $avr-color-blue-70;
$navbar-inverse-link-color:       $avr-color-gray-80;
$navbar-inverse-link-hover-color: $avr-color-white;

$avr-sidebar-width:           percentage(0.14);
$avr-cards-submenu-width:     percentage(0.2);
$avr-content-padding:         $avr-space-m;

$avr-navbar-height:           45px;

// main navigation
$avr-main-navigation-width:           240px;
$avr-main-navigation-header-height:   52px;

$control-transition:          cubic-bezier(0.64, 0, 0.35, 1);

// headings
$headings-small-color:        inherit;

// buttons
$btn-border-radius-base:      $avr-border-radius-25;
$btn-border-radius-large:     $avr-border-radius-50;
$btn-gradient:                none;

$btn-default-bg:              transparent;
$btn-default-bg-hover:        $avr-color-gray-98;
$btn-default-bg-active:       $avr-color-gray-98;
$btn-default-bg-disabled:     transparent;
$btn-default-border:          $avr-color-gray-80;
$btn-default-border-hover:    $avr-color-gray-80;
$btn-default-border-active:   $avr-color-gray-80;
$btn-default-color:           $avr-color-gray-10;

$btn-primary-bg:              $avr-color-blue-40;
$btn-primary-bg-hover:        $avr-color-blue-50;
$btn-primary-bg-active:       $avr-color-blue-50;
$btn-primary-bg-disabled:     $avr-color-blue-80;
$btn-primary-border:          $avr-color-blue-40;
$btn-primary-border-hover:    $avr-color-blue-50;
$btn-primary-border-active:   $avr-color-blue-80;

$btn-success-bg:               $avr-color-green-40;
$btn-success-bg-hover:         $avr-color-green-50;
$btn-success-bg-active:        $avr-color-green-50;
$btn-success-bg-disabled:      $avr-color-green-80;
$btn-success-border:           $avr-color-green-40;
$btn-success-border-hover:     $avr-color-green-50;
$btn-success-border-active:    $avr-color-green-80;

$btn-danger-bg:               $avr-color-red-40;
$btn-danger-bg-hover:         $avr-color-red-50;
$btn-danger-bg-active:        $avr-color-red-50;
$btn-danger-bg-disabled:      $avr-color-red-80;
$btn-danger-border:           $avr-color-red-40;
$btn-danger-border-hover:     $avr-color-red-50;
$btn-danger-border-active:    $avr-color-red-80;

$btn-driver-dashboard-bg:               $avr-color-gray-95;
$btn-driver-dashboard-border:           $avr-color-gray-95;
$btn-driver-dashboard-active:           $avr-color-gray-90;
$btn-driver-dashboard-shadow:           rgba($avr-color-gray-10, 0.2);

// badges
$badge-bg:                    $avr-color-gray-50;
$badge-danger-light:          $avr-color-red-90;
$badge-danger-dark:           $avr-color-red-30;
$badge-font-weight:           $avr-font-weight-semibold;
$badge-slightly-transparent:  $avr-color-white-alpha-10;

// Pools
$pools-reservation-bg:          $avr-color-gray-80;
$pools-reservation-line-text:   $avr-color-gray-98;
$pools-selectable-header-bg:    $avr-color-gray-95;

// Table
$table-bg-hover:                rgba($avr-color-gray-98, 0.75);
$table-cell-padding:            10px;
$table-condensed-cell-padding:  8px;
$table-border-color:            $avr-color-gray-90;

// Pagination
$pagination-active-bg:          $avr-color-gray-90;
$pagination-hover-bg:           $avr-color-white;
$pagination-active-border:      $avr-color-gray-30;
$pagination-hover-border:       $avr-color-gray-30;
$pagination-color:              $avr-color-gray-10;
$pagination-active-color:       $avr-color-gray-10;
$pagination-hover-color:        $avr-color-gray-10;

$font-size-h2: 28px;

// grid
$grid-gutter-width:           2 * $avr-content-padding;
$one-third:                   percentage(math.div(1, 3));
$two-thirds:                  percentage(math.div(2, 3));

// navbar
$navbar-height:               $avr-navbar-height;

// animation settings
$avr-easing:                  ease-in-out;
$avr-animation-duration:      0.2s;

$thumbnailRatio:              75%;
$form-label-padding-vertical: 3px;

// modal settings
$modal-inner-padding:         $avr-space-l;
$avr-modal-border-radius:     $avr-border-radius-50;

// depth settings
$avr-depth-z50-shadow:        0 4px 2px -3px rgba($avr-color-gray-10, 0.12);
$avr-depth-z100-shadow:       0 2px 4px -2px rgba($avr-color-gray-10, 0.08), 0 3px 4px -2px rgba($avr-color-gray-10, 0.07), 0 6px 8px -2px rgba($avr-color-gray-10, 0.06);
$avr-depth-z200-backdrop:     rgba($avr-color-brand-black, 0.1);
$avr-depth-z200-shadow:       0 3px 2px -3px rgba($avr-color-gray-10, 0.2), 0 4px 5px -3px rgba($avr-color-gray-10, 0.16), 0 10px 17px -2px rgba($avr-color-gray-10, 0.12);
$avr-depth-z300-backdrop:     rgba($avr-color-brand-black, 0.35);
$avr-depth-z300-shadow:       0 4px 2px -4px rgba($avr-color-gray-10, 0.24), 0 8px 8px -4px rgba($avr-color-gray-10, 0.2), 0 16px 16px -2px rgba($avr-color-gray-10, 0.16);

// Hamburger menu
$hamburger-types:         'squeeze';
$hamburger-layer-color:   $avr-color-brand-black;
$hamburger-layer-width:   $avr-space-l;
$hamburger-layer-height:  2px;
$hamburger-layer-spacing: 4px;
$hamburger-padding-x:     $avr-space-s;
$hamburger-padding-y:     $avr-space-s;

$avr-tree-line-color:             $avr-color-gray-80;
$avr-tree-item-color:             $avr-color-gray-95;
$avr-tree-item-color-hover:       $avr-color-gray-90;
$avr-tree-item-spacing:           $avr-space-s;
$avr-tree-item-padding:           $avr-space-s;
$avr-tree-toolbar-icon-size:      $avr-font-size-base;
$avr-tree-toolbar-icon-spacing:   6px;

// Empty state
$empty-state-max-width: 550px;

// Promotion banner
$avr-promotion-banner-max-width: 825px;

// Vehicle Importer Table
$vehicle-import-medium: 700px;
$vehicle-import-large: 900px;

$max-width-container: 900px;
$max-width-container-xl-screen: 1200px;

// Vehicle TCO
$vehicle-tco-empty-state-max-width: 730px;

// screen
$screen-xl: 1980px;
$screen-l: 1400px;
$screen-height-m: 715px;
$screen-height-ls: 800px;
$screen-height-l: 900px;

// Disabled opacity
$opacity-disabled: 0.6;
