.ui-select-container.ui-select-bootstrap {
  &.ui-select-container--size-sm input.form-control {
    height: 30px;
    padding: 6px 10px 4px;
    font-size: 12px;
    line-height: 1.5;
  }

  .ui-select-placeholder {
    position: relative;
    padding-right: 15px;
    display: contents;

    // If it's a smaller input align the placeholder
    .form-group-sm & {
      top: 1px;
    }
  }

  .ui-select-search,
  .ui-select-toggle {
    width: 100%;
    min-width: 100%;

    &[disabled] {
      background-color: $input-bg-disabled;
    }
  }

  .ui-select-match-text {
    width: 100%;
    padding-right: 2em;

    span {
      display: inline;
    }

    & > span {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // ui-select is adding outlines on focus
  .ui-select-match {
    outline: none;
    box-shadow: none;
  }

  // We have a gradient on our button and we're cleaning it up on the select here
  .ui-select-toggle.btn.btn-default {
    background-image: none;
    background-color: $avr-color-white;
    box-shadow: 0 0 0 0 rgba($avr-color-brand-black, 0), 0 0 0 12px rgba($avr-color-brand-black, 0);
    margin: 0;
    border-radius: $avr-border-radius-50;

    &:hover {
      border: 1px solid $input-border;
      background-color: $avr-color-white;
      box-shadow: 0 0 0 0 rgba($avr-color-brand-black, 0), 0 0 0 12px rgba($avr-color-brand-black, 0);
    }

    .has-error & {
      border-color: $input-danger-border;
      background-color: $input-danger-bg;
    }

    .caret {
      @extend %avricon-base;
      @extend .avricon-chevron-down;

      font-size: 15px;
      border: none;
      margin-top: -10px;
      right: $avr-space-l;
    }

    // Remove icon
    > a.btn {
      margin-top: -8px;
      color: $avr-color-gray-30;

      .glyphicon {
        // Use our avricon remove icon
        @extend %avricon-base;
        @extend .avricon-remove;
      }
    }
  }

  // When the select has an error and we focus the input
  .ui-select-search {
    .has-error & {
      box-shadow: 0 0 0 0 rgba($avr-color-brand-black, 0), 0 0 0 12px rgba($avr-color-brand-black, 0);
      border-color: $input-danger-border;
      background-color: $input-danger-bg;
      caret-color: $input-danger-border;

      &:focus {
        border-color: $input-danger-border-focus;
        box-shadow: 0 0 0 1px $input-danger-border-focus, 0 0 0 0 $input-danger-border-focus;
      }
    }
  }

  .btn-default-focus .ui-select-toggle {
    box-shadow: 0 0 0 1px $input-border-focus, 0 0 0 0 $input-border-focus;
    transition: all 0.24s 0.11s $control-transition;
    background-color: lighten($avr-color-blue-90, 1.7%);
    border-color: $input-border-focus;

    .has-error & {
      box-shadow: 0 0 0 1px $input-danger-border-focus, 0 0 0 0 $input-danger-border-focus;
      background-color: $input-danger-bg;
      border-color: $input-danger-border-focus;
    }
  }

  .ui-select-choices,
  .ui-select-no-choice {
    // Prevent longer select option from being cut off
    width: initial;
    min-width: 100%;
  }

  .ui-select-choices.dropdown-menu li {
    border-bottom: none;
  }

  .ui-select-choices-row {
    & > span {
      padding: 5px 10px;

      &:focus,
      &:hover {
        background-color: $avr-color-gray-90;
        color: $avr-color-brand-black;
      }
    }

    & > a {
      white-space: normal;
    }

    &.active > span {
      color: $avr-color-brand-black;
      background: $avr-color-gray-95;
    }

    &.disabled > span {
      color: $avr-color-gray-80;

      .ui-select-highlight {
        color: $avr-color-gray-50;
      }
    }

    .ui-select-highlight {
      color: $avr-color-blue-50;
    }

    &__checkbox {
      height: 20px;
      margin: 0;
    }
  }

  // Normal ui-selects don't have sm version
  .form-group-sm &,
  &.input-sm {
    .ui-select-match-text {
      padding-right: 2.5em;
    }
  }

  &.ui-select-multiple {
    .ui-select-match-item {
      border-radius: $avr-border-radius-50;
      font-size: 14px;
      font-weight: $avr-font-weight-semibold;
      line-height: 14px;
      background-image: none;
      background-color: $avr-color-gray-90;
      border-color: $avr-color-gray-90;
      box-shadow: none;
      color: $avr-color-gray-10;
      margin-bottom: 4px;
      white-space: normal;
      text-align: left;
      padding: {
        top: 2px;
        bottom: 2px;
      }

      .ui-select-match-close {
        opacity: 1;
        color: $avr-color-gray-10;
        text-shadow: none;
      }
    }

    .ui-select-search,
    .ui-select-toggle {
      min-width: 0;
    }
  }

  .dropdown-header {
    padding-left: 10px;
    padding-right: 10px;
  }
}

td {
  .ui-select-container.ui-select-bootstrap,
  .ui-select-search {
    min-width: 100%;
  }

  .input-field {
    .ui-select-container.ui-select-bootstrap,
    .ui-select-search {
      min-width: initial;
    }
  }
}

.match-ui-select,
.match-ui-select-model,
.match-ui-select-driver,
.match-ui-select-vehicle,
.match-ui-select-manufacturer {
  @include clearfix;
}

.match-ui-select-owner {
  display: block;

  img {
    height: 25px;
    margin-top: 7px;
  }

  .description {
    margin: 0;
    padding-top: 5px;
    display: block;

    .owner-name,
    .cost-center {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .owner-name {
      margin: 0;
    }

    .cost-center {
      font-size: smaller;
      color: $avr-color-gray-50;
    }
  }
}

.ui-select-bootstrap {
  &.has-checkboxes .ui-select-choices-row > span {
    // when ui-select calculates the width of the dropdown
    // our checkboxes (if present) are not rendered yet
    // so the list of options is not wide enough
    // this accounts for 10px normal padding + 25px for the checkbox
    padding-right: 35px;
  }

  .ui-select-choices-row {
    .item,
    .line {
      display: block;
    }

    .item .avricon:first-child {
      width: 16px;
      height: 16px;
      line-height: 16px;
      text-align: center;
    }

    .line-wrap {
      white-space: initial;
    }
  }

  .ui-select-match-text {
    span.line {
      display: inline;
      width: auto;
    }

    .line,
    .text {
      &:after {
        content: ', ';
      }

      &:last-child:after {
        display: none;
      }
    }

    .option-prefix,
    .option-suffix {
      display: inline;
    }

    .option-prefix {
      & + .text {
        display: inline;
      }
    }
  }

  .ui-select-choices-row-inner {
    .item__field-description {
      margin-left: 18px;
    }
  }

  &.group-by-prefix {
    .option-prefix {
      display: none;

      &:after {
        content: ': ';
      }
    }

    .ui-select-match-text {
      .option-prefix {
        display: inline;
      }
    }
  }
}

.ui-select-match {
  .btn {
    &.disabled,
    &[disabled] {
      &:focus,
      &:hover {
        background-color: $avr-color-gray-90;
      }
    }
  }
}

.ui-select-table-column {
  .ui-select-toggle {
    padding: 6px 12px;
  }

  .ui-select-placeholder {
    // we need to override the placeholder size because it is way too big
    // we also need to use important because the height style is inline
    height: 34px !important;
  }

  .ui-select-container.ng-not-empty {
    width: 100%;
  }

  .ui-select-container {
    width: 160px;
  }

  .ui-select-container.open {
    width: 160px;
  }
}
