@import '../avricons-functions';

$avricon-car-dashboard-gear: unicode('e9e2');
$avricon-vehicle-check: unicode('e9e3');
$avricon-add-circle: unicode('e9e4');
$avricon-add-square: unicode('e9e5');
$avricon-add-thin: unicode('e9e6');
$avricon-aircraft-airship-side: unicode('e9e7');
$avricon-alert-triangle: unicode('e9e8');
$avricon-alert: unicode('e9e9');
$avricon-analytic-check: unicode('e9ea');
$avricon-analytic-currency: unicode('e9eb');
$avricon-analytic-percentage: unicode('e9ec');
$avricon-animal-honey-comb: unicode('e9ed');
$avricon-animal-pet-home: unicode('e9ee');
$avricon-astronomy-rocket: unicode('e9ef');
$avricon-award-flag: unicode('e9f0');
$avricon-bank-euro: unicode('e9f1');
$avricon-bank: unicode('e9f2');
$avricon-bell-notifiaction-mute-disable: unicode('e9f3');
$avricon-bell-notification-snooze: unicode('e9f4');
$avricon-bell-notification: unicode('e9f5');
$avricon-billing-check: unicode('e9f6');
$avricon-bin-disabled: unicode('e9f7');
$avricon-book-bookmark: unicode('e9f8');
$avricon-book-contact-account: unicode('e9f9');
$avricon-book-open-1: unicode('e9fa');
$avricon-brief-case-emphasis: unicode('e9fb');
$avricon-briefcase-plus: unicode('e9fc');
$avricon-briefcase: unicode('e9fd');
$avricon-business-document-bill-finacial-move: unicode('e9fe');
$avricon-business-document-bill-finacial: unicode('e9ff');
$avricon-button-pause: unicode('ea00');
$avricon-calendar-edit: unicode('ea01');
$avricon-calendar-filled: unicode('ea02');
$avricon-camera-timer: unicode('ea03');
$avricon-cash-briefcase: unicode('ea04');
$avricon-check-box: unicode('ea05');
$avricon-check-circle-inside: unicode('ea06');
$avricon-check-double-thin: unicode('ea07');
$avricon-chevron-down-m: unicode('ea08');
$avricon-chevron-left-m: unicode('ea09');
$avricon-chevron-right-m: unicode('ea0a');
$avricon-chevron-up-m: unicode('ea0b');
$avricon-circle-double: unicode('ea0c');
$avricon-circle-tilde: unicode('ea0d');
$avricon-clock-fire-alarm: unicode('ea0e');
$avricon-clock-time-share: unicode('ea0f');
$avricon-cloud-progress-quarter: unicode('ea10');
$avricon-cloud-upload: unicode('ea11');
$avricon-cloud-wind: unicode('ea12');
$avricon-coffee-cup: unicode('ea13');
$avricon-common-file-download: unicode('ea14');
$avricon-common-file-duplicate: unicode('ea15');
$avricon-common-file: unicode('ea16');
$avricon-common-files: unicode('ea17');
$avricon-compass-navigator: unicode('ea18');
$avricon-compass-two-direction: unicode('ea19');
$avricon-component-car-fuel-tank: unicode('ea1a');
$avricon-component-car-key: unicode('ea1b');
$avricon-component-car-steering-wheel: unicode('ea1c');
$avricon-component-car-wheel: unicode('ea1d');
$avricon-construction-backhoe: unicode('ea1e');
$avricon-construction-hoist-lifter: unicode('ea1f');
$avricon-construction-truck: unicode('ea20');
$avricon-contract-leasing: unicode('ea21');
$avricon-contract-purchase: unicode('ea22');
$avricon-contract-rent: unicode('ea23');
$avricon-contract-star: unicode('ea24');
$avricon-credit-card-add: unicode('ea25');
$avricon-credit-card-disabled: unicode('ea26');
$avricon-credit-card-gas-fuel: unicode('ea27');
$avricon-currency-coin-dollar: unicode('ea28');
$avricon-currency-coin-euro-plus: unicode('ea29');
$avricon-currency-euro-text: unicode('ea2a');
$avricon-currency-euro: unicode('ea2b');
$avricon-currency-krona-text: unicode('ea2c');
$avricon-currency-pound: unicode('ea2d');
$avricon-currency-swissfranc-text: unicode('ea2e');
$avricon-currency-usd-dollar: unicode('ea2f');
$avricon-currency-zloty-text: unicode('ea30');
$avricon-cursor-select-circle: unicode('ea31');
$avricon-cursor-select-frame-out: unicode('ea32');
$avricon-cursor-select-frame: unicode('ea33');
$avricon-cursor: unicode('ea34');
$avricon-dashboard-gas-empty: unicode('ea35');
$avricon-dashboard-gas-full: unicode('ea36');
$avricon-dashboard-gas: unicode('ea37');
$avricon-dashboard-gear-indicator-auto: unicode('ea38');
$avricon-dashboard-speed-guage: unicode('ea39');
$avricon-data-transfer-circle-upload: unicode('ea3a');
$avricon-data-transfer-content-unload: unicode('ea3b');
$avricon-data-transfer-download: unicode('ea3c');
$avricon-data-transfer-square-upload: unicode('ea3d');
$avricon-data-transfer-traffic-diagonal: unicode('ea3e');
$avricon-data-transfer-traffic-horizontal: unicode('ea3f');
$avricon-data-transfer-traffic-square: unicode('ea40');
$avricon-data-transfer-traffic-vertical: unicode('ea41');
$avricon-data-transfer-upload: unicode('ea42');
$avricon-design-tool-rotate-clockwise: unicode('ea43');
$avricon-design-tool-rotate-counterclockwise: unicode('ea44');
$avricon-disable-delete: unicode('ea45');
$avricon-disable-remove-circle: unicode('ea46');
$avricon-dot: unicode('ea47');
$avricon-drive-start: unicode('ea48');
$avricon-email-envelope-1: unicode('ea49');
$avricon-email-inbox-read: unicode('ea4a');
$avricon-email-send-envelope: unicode('ea4b');
$avricon-entertainment-ticket: unicode('ea4c');
$avricon-equal: unicode('ea4d');
$avricon-expand-all-direction: unicode('ea4e');
$avricon-expand-vertical: unicode('ea4f');
$avricon-eye-disabled: unicode('ea50');
$avricon-favorite-heart: unicode('ea51');
$avricon-file-document: unicode('ea52');
$avricon-file-favorite-star: unicode('ea53');
$avricon-file-pdf-e-book: unicode('ea54');
$avricon-file-question: unicode('ea55');
$avricon-file-search-dollar: unicode('ea56');
$avricon-file-text: unicode('ea57');
$avricon-file-upload: unicode('ea58');
$avricon-filter-3: unicode('ea59');
$avricon-flash-enable-allow: unicode('ea5a');
$avricon-flip-horizontal-right: unicode('ea5b');
$avricon-flip-vertical-down: unicode('ea5c');
$avricon-focus: unicode('ea5d');
$avricon-folder-dash: unicode('ea5e');
$avricon-globe-wireframe: unicode('ea5f');
$avricon-graph-bar-horizontal-left: unicode('ea60');
$avricon-graph-bar-horizontal: unicode('ea61');
$avricon-graph-bar-vertical: unicode('ea62');
$avricon-graph-pie-1: unicode('ea63');
$avricon-graph-stats-ascend: unicode('ea64');
$avricon-graph-stats-descend: unicode('ea65');
$avricon-hashtag: unicode('ea66');
$avricon-hierarchy-circle-triangle: unicode('ea67');
$avricon-home-button-emphasis: unicode('ea68');
$avricon-home-button: unicode('ea69');
$avricon-hourglass-flip: unicode('ea6a');
$avricon-hyperlink-break: unicode('ea6b');
$avricon-hyperlink: unicode('ea6c');
$avricon-id-card-disabled: unicode('ea6d');
$avricon-id-card: unicode('ea6e');
$avricon-image-file-jpg: unicode('ea6f');
$avricon-image-file-png: unicode('ea70');
$avricon-image-file: unicode('ea71');
$avricon-infinite-loop: unicode('ea72');
$avricon-information-circle: unicode('ea73');
$avricon-keyboard-arrow-back: unicode('ea74');
$avricon-keyboard-arrow-next: unicode('ea75');
$avricon-keyboard-down-arrow: unicode('ea76');
$avricon-keyboard-left-arrow: unicode('ea77');
$avricon-keyboard-up-arrow: unicode('ea78');
$avricon-kitchenware-fork-knife: unicode('ea79');
$avricon-layers: unicode('ea7a');
$avricon-layout-dashboard-4: unicode('ea7b');
$avricon-layout-three-columns: unicode('ea7c');
$avricon-layout-thumbnail-four: unicode('ea7d');
$avricon-layout-thumbnail-nine: unicode('ea7e');
$avricon-layout-thumbnail-six: unicode('ea7f');
$avricon-layout-thumbnail-three: unicode('ea80');
$avricon-layout-top-module: unicode('ea81');
$avricon-light-bulb: unicode('ea82');
$avricon-line-chart-filled: unicode('ea83');
$avricon-line-chart: unicode('ea84');
$avricon-list-bullet-point-circle: unicode('ea85');
$avricon-lock-square-unlock: unicode('ea86');
$avricon-lock-square: unicode('ea87');
$avricon-log-in-square: unicode('ea88');
$avricon-log-out-square: unicode('ea89');
$avricon-magnifier: unicode('ea8a');
$avricon-map-pin-bell: unicode('ea8b');
$avricon-map-pin-favorite-star: unicode('ea8c');
$avricon-maps-fold-pocket: unicode('ea8d');
$avricon-maps-location-pin: unicode('ea8e');
$avricon-maps-none-precision-gps: unicode('ea8f');
$avricon-maps-pin-emphasis: unicode('ea90');
$avricon-maps-pin-thin: unicode('ea91');
$avricon-maps-pin: unicode('ea92');
$avricon-maps-precision-gps: unicode('ea93');
$avricon-market-statistics-1: unicode('ea94');
$avricon-maximize-square: unicode('ea95');
$avricon-megaphone-1: unicode('ea96');
$avricon-menu-more-horizontal: unicode('ea97');
$avricon-menu-more-vertical: unicode('ea98');
$avricon-message-chat-circle: unicode('ea99');
$avricon-message-chat-content: unicode('ea9a');
$avricon-message-chat-round-schedule-clock: unicode('ea9b');
$avricon-microsoft-excel-logo: unicode('ea9c');
$avricon-motorbicycle-scooter: unicode('ea9d');
$avricon-move-right-3: unicode('ea9e');
$avricon-music-player-button-pause: unicode('ea9f');
$avricon-music-player-button-play: unicode('eaa0');
$avricon-music-player-volume-high: unicode('eaa1');
$avricon-navigation-left-two: unicode('eaa2');
$avricon-navigation-right-two: unicode('eaa3');
$avricon-note-edit: unicode('eaa4');
$avricon-notebook-pencil: unicode('eaa5');
$avricon-object-weight: unicode('eaa6');
$avricon-office-book-shelf: unicode('eaa7');
$avricon-page-content: unicode('eaa8');
$avricon-paperclip-1: unicode('eaa9');
$avricon-paragraph-indent-left: unicode('eaaa');
$avricon-paragraph-indent-right: unicode('eaab');
$avricon-paragraph-left-align-disabled: unicode('eaac');
$avricon-paragraph-left-align: unicode('eaad');
$avricon-pencil-1: unicode('eaae');
$avricon-phone-action-dismiss: unicode('eaaf');
$avricon-phone-action: unicode('eab0');
$avricon-plant-leaf: unicode('eab1');
$avricon-political-globe: unicode('eab2');
$avricon-power-button: unicode('eab3');
$avricon-press: unicode('eab4');
$avricon-printer-document: unicode('eab5');
$avricon-question-circle: unicode('eab6');
$avricon-real-estate-skyscraper: unicode('eab7');
$avricon-redo-arrow: unicode('eab8');
$avricon-refresh-arrow: unicode('eab9');
$avricon-remove-shield: unicode('eaba');
$avricon-replay-repeat-arrow: unicode('eabb');
$avricon-respondsive-design: unicode('eabc');
$avricon-route-sign-parking-area: unicode('eabd');
$avricon-route-sign-parking-not-allow: unicode('eabe');
$avricon-route-sign-staight: unicode('eabf');
$avricon-route-sign-traffic-light-ahead: unicode('eac0');
$avricon-route-sign-u-turn-ahead: unicode('eac1');
$avricon-school-graduate: unicode('eac2');
$avricon-settings-cog-1: unicode('eac3');
$avricon-settings-slider-horizontal-1: unicode('eac4');
$avricon-share-box: unicode('eac5');
$avricon-shield-check: unicode('eac6');
$avricon-shield-question: unicode('eac7');
$avricon-shield-user: unicode('eac8');
$avricon-shiftmove: unicode('eac9');
$avricon-shop: unicode('eaca');
$avricon-shopping-cart-full: unicode('eacb');
$avricon-shopping-cart: unicode('eacc');
$avricon-shrink-circle: unicode('eacd');
$avricon-shrink-diagonal: unicode('eace');
$avricon-shuffle-play-arrow: unicode('eacf');
$avricon-speed-guage-display: unicode('ead0');
$avricon-star-circle: unicode('ead1');
$avricon-subtract-small: unicode('ead2');
$avricon-synchronize-arrow-time: unicode('ead3');
$avricon-synchronize-arrows: unicode('ead4');
$avricon-tag-barcode-search-check: unicode('ead5');
$avricon-tag-currency-euro: unicode('ead6');
$avricon-tag-double: unicode('ead7');
$avricon-task-list-check: unicode('ead8');
$avricon-task-list: unicode('ead9');
$avricon-tool-box: unicode('eada');
$avricon-tool-traffic-cone: unicode('eadb');
$avricon-tool-wrench-hand: unicode('eadc');
$avricon-toolbox: unicode('eadd');
$avricon-travel-luggage: unicode('eade');
$avricon-trip-category: unicode('eadf');
$avricon-truck-caravan-house-car: unicode('eae0');
$avricon-truck-liquid-container: unicode('eae1');
$avricon-undo-arrow: unicode('eae2');
$avricon-user-business-man: unicode('eae3');
$avricon-user-geometric-action-add-disabled: unicode('eae4');
$avricon-user-geometric-action-add: unicode('eae5');
$avricon-user-geometric-action-check-approve: unicode('eae6');
$avricon-user-geometric-action-mail: unicode('eae7');
$avricon-user-geometric-action-phone: unicode('eae8');
$avricon-user-geometric-action-restrict: unicode('eae9');
$avricon-user-geometric-action: unicode('eaea');
$avricon-user-multiple-minus: unicode('eaeb');
$avricon-user-multiple-plus: unicode('eaec');
$avricon-user-multiple: unicode('eaed');
$avricon-user-profile-circle: unicode('eaee');
$avricon-vehicle-burning-hot: unicode('eaef');
$avricon-vehicle-car-personal-disabled: unicode('eaf0');
$avricon-vehicle-car-personal-emphasis: unicode('eaf1');
$avricon-vehicle-car-personal-side: unicode('eaf2');
$avricon-vehicle-car-personal: unicode('eaf3');
$avricon-vehicle-dollar: unicode('eaf4');
$avricon-vehicle-garage: unicode('eaf5');
$avricon-vehicle-lifter: unicode('eaf6');
$avricon-vehicle-network: unicode('eaf7');
$avricon-vehicle-person: unicode('eaf8');
$avricon-vehicle-plus: unicode('eaf9');
$avricon-vehicle-protection-shield: unicode('eafa');
$avricon-vehicle-questionmark: unicode('eafb');
$avricon-vehicle-restrict: unicode('eafc');
$avricon-vehicle-search: unicode('eafd');
$avricon-vehicle-tag: unicode('eafe');
$avricon-vehicle-tractor: unicode('eaff');
$avricon-vehicle-transporter: unicode('eb00');
$avricon-vehicle-washing-service: unicode('eb01');
$avricon-vehicle-wrench-tool: unicode('eb02');
$avricon-video-camera: unicode('eb03');
$avricon-vimcar: unicode('eb04');
$avricon-water-protection-drop: unicode('eb05');
$avricon-weather-sun: unicode('eb06');
$avricon-wifi-network-disable-restrict: unicode('eb07');
$avricon-wifi-network: unicode('eb08');
$avricon-wrench-hammer: unicode('eb09');
$avricon-zigma: unicode('eb0a');
$avricon-zigzag-merge-arrow: unicode('eb0b');
$avricon-zigzag-split-arrow: unicode('eb0c');
$avricon-callcenter-help: unicode('e9e1');
$avricon-diamond: unicode('e9e0');
$avricon-email-send: unicode('e941');
$avricon-link: unicode('e965');
$avricon-open-book: unicode('e970');
$avricon-stacked-bar-chart: unicode('e981');
$avricon-rocket: unicode('e9dc');
$avricon-speeding: unicode('e9d6');
$avricon-three-dots: unicode('e9d7');
$avricon-parking-forbidden: unicode('e9d8');
$avricon-red-light: unicode('e9d9');
$avricon-workshop: unicode('e9d5');
$avricon-unlink-item: unicode('e9d4');
$avricon-store: unicode('e9d2');
$avricon-damage-cone: unicode('e9d0');
$avricon-fuel-card: unicode('e9d1');
$avricon-table-view: unicode('e9ce');
$avricon-group-view: unicode('e9cf');
$avricon-cloud: unicode('e9cc');
$avricon-water-drop: unicode('e9cd');
$avricon-emissions: unicode('e9ca');
$avricon-arrow-back-long: unicode('e9c7');
$avricon-approved: unicode('e9c2');
$avricon-warning: unicode('e9c4');
$avricon-dollar: unicode('e9c5');
$avricon-file-dollar-search: unicode('e9c0');
$avricon-ready: unicode('e9bf');
$avricon-leased-car: unicode('e9bd');
$avricon-car-block: unicode('e9bc');
$avricon-redo: unicode('e98b');
$avricon-build-up: unicode('e9bb');
$avricon-folder-add: unicode('e9b7');
$avricon-move: unicode('e9b9');
$avricon-control-pause: unicode('e9b5');
$avricon-speaker-volume-high: unicode('e9b8');
$avricon-timer: unicode('e9b4');
$avricon-external-link: unicode('e9b3');
$avricon-subtract-circle: unicode('e9ad');
$avricon-arrow-sync: unicode('e9a9');
$avricon-contract: unicode('e9a8');
$avricon-flash: unicode('e9a7');
$avricon-columns: unicode('e9a6');
$avricon-notifications: unicode('e99c');
$avricon-remove: unicode('e945');
$avricon-costs: unicode('e986');
$avricon-invoice-import: unicode('e98a');
$avricon-task: unicode('e94c');
$avricon-settings: unicode('e984');
$avricon-help: unicode('e985');
$avricon-driver-person: unicode('e983');
$avricon-home: unicode('e982');
$avricon-chevron-left: unicode('e914');
$avricon-chevron-right: unicode('e915');
$avricon-chevron-up: unicode('e916');
$avricon-chevron-down: unicode('e917');
$avricon-banking-office-hour: unicode('e980');
$avricon-add: unicode('e960');
$avricon-address-book: unicode('e901');
$avricon-clock: unicode('e9b2');
$avricon-alarm-clock: unicode('e902');
$avricon-clock-fire: unicode('e9af');
$avricon-graduation-hat: unicode('e9b0');
$avricon-alert-circle: unicode('e904');
$avricon-alert-document: unicode('e905');
$avricon-arrow-enter-right: unicode('e907');
$avricon-arrow-left: unicode('e908');
$avricon-arrow-right: unicode('e909');
$avricon-arrow-down: unicode('e906');
$avricon-arrow-up: unicode('e90a');
$avricon-bin: unicode('e90b');
$avricon-bus: unicode('e90c');
$avricon-calendar: unicode('e90d');
$avricon-calendar-blank: unicode('e990');
$avricon-calendar-dollar: unicode('e9aa');
$avricon-camera: unicode('e973');
$avricon-car: unicode('e90e');
$avricon-car-signal: unicode('e96c');
$avricon-card: unicode('e90f');
$avricon-cart: unicode('e961');
$avricon-chat-bubble: unicode('e903');
$avricon-chat-bubble-timeout: unicode('e969');
$avricon-check: unicode('e913');
$avricon-check-circle: unicode('e910');
$avricon-check-multiple: unicode('e911');
$avricon-check-shield: unicode('e912');
$avricon-circle-cash: unicode('e9ab');
$avricon-close-shield: unicode('e919');
$avricon-cloud-progress: unicode('e91a');
$avricon-coffee: unicode('e978');
$avricon-cog: unicode('e91b');
$avricon-company: unicode('e900');
$avricon-cost-center: unicode('e91c');
$avricon-damage: unicode('e91d');
$avricon-devices: unicode('e971');
$avricon-download: unicode('e91e');
$avricon-drag-handle: unicode('e91f');
$avricon-driver: unicode('e920');
$avricon-envelope: unicode('e921');
$avricon-excavator: unicode('e922');
$avricon-excel: unicode('e923');
$avricon-eye: unicode('e924');
$avricon-file: unicode('e929');
$avricon-file-copy: unicode('e976');
$avricon-file-edit: unicode('e925');
$avricon-file-image: unicode('e962');
$avricon-file-incomplete: unicode('e926');
$avricon-file-pdf: unicode('e927');
$avricon-file-refresh: unicode('e928');
$avricon-file-star: unicode('e96a');
$avricon-financing: unicode('e92a');
$avricon-flag: unicode('e963');
$avricon-flip-horizontally: unicode('e97b');
$avricon-flip-vertically: unicode('e97c');
$avricon-folder: unicode('e92d');
$avricon-folder-lock: unicode('e92b');
$avricon-folder-move: unicode('e92c');
$avricon-forklift: unicode('e92e');
$avricon-fuel: unicode('e92f');
$avricon-gauge: unicode('e964');
$avricon-gift: unicode('e930');
$avricon-hash: unicode('e991');
$avricon-heart: unicode('e979');
$avricon-hide: unicode('e931');
$avricon-hive: unicode('e932');
$avricon-hourglass: unicode('e9b1');
$avricon-inbox: unicode('e933');
$avricon-info: unicode('e966');
$avricon-insurance: unicode('e934');
$avricon-invoice: unicode('e935');
$avricon-license: unicode('e96f');
$avricon-list-bullets: unicode('e97a');
$avricon-lock: unicode('e937');
$avricon-lock-open: unicode('e936');
$avricon-login: unicode('e938');
$avricon-logout: unicode('e939');
$avricon-maintenance: unicode('e93a');
$avricon-megaphone: unicode('e93b');
$avricon-mileage: unicode('e93c');
$avricon-motorcycle: unicode('e93d');
$avricon-paperclip: unicode('e967');
$avricon-pause-circle: unicode('e96e');
$avricon-pencil: unicode('e93e');
$avricon-phone: unicode('e93f');
$avricon-phone-off: unicode('e9ba');
$avricon-printer: unicode('e940');
$avricon-question-shield: unicode('e942');
$avricon-refresh: unicode('e943');
$avricon-remove-circle: unicode('e944');
$avricon-rotate-clockwise: unicode('e946');
$avricon-rotate-counter-clockwise: unicode('e947');
$avricon-salesrep: unicode('e958');
$avricon-search: unicode('e948');
$avricon-share-link: unicode('e975');
$avricon-share-time: unicode('e9ac');
$avricon-show-more-vertical: unicode('e949');
$avricon-shrink: unicode('e96b');
$avricon-smartphone: unicode('e977');
$avricon-special: unicode('e94a');
$avricon-string: unicode('e992');
$avricon-sun: unicode('e94b');
$avricon-tractor: unicode('e94d');
$avricon-trailer: unicode('e94e');
$avricon-transfer: unicode('e94f');
$avricon-transporter: unicode('e950');
$avricon-trolley-box: unicode('e951');
$avricon-truck: unicode('e952');
$avricon-unknown: unicode('e953');
$avricon-upload: unicode('e954');
$avricon-user: unicode('e957');
$avricon-user-block: unicode('e955');
$avricon-user-chat: unicode('e968');
$avricon-user-group: unicode('e956');
$avricon-vehicle: unicode('e95a');
$avricon-vehicle-snooze: unicode('e959');
$avricon-vehicles: unicode('e95b');
$avricon-virtual: unicode('e95c');
$avricon-vote-star-circle: unicode('e972');
$avricon-window-list: unicode('e974');
$avricon-wrench: unicode('e95d');
$avricon-zoom-in: unicode('e95e');
$avricon-zoom-out: unicode('e95f');
$avricon-trend-up: unicode('e97d');
$avricon-trend-down: unicode('e97e');
$avricon-user-shield: unicode('e97f');
$avricon-plus-circle: unicode('e988');
$avricon-medical-shield: unicode('e989');
$avricon-alarm-disabled: unicode('e98c');
$avricon-arrow-left-end: unicode('e98e');
$avricon-arrow-right-end: unicode('e98f');
$avricon-play-circle: unicode('e993');
$avricon-chart-pie: unicode('e994');
$avricon-power: unicode('e918');
$avricon-arrow-up-circle: unicode('e995');
$avricon-file-arrow-up: unicode('e996');
$avricon-filter: unicode('e997');
$avricon-allianz: unicode('e998');
$avricon-money-note: unicode('e999');
$avricon-globe: unicode('e99a');
$avricon-circle-percent: unicode('e99b');
$avricon-icon-yes-no: unicode('e99d');
$avricon-nature-leaf: unicode('e99e');
$avricon-dollar-circle: unicode('e99f');
$avricon-location-pin: unicode('e9a0');
$avricon-speedometer: unicode('e9a1');
$avricon-weight: unicode('e9a2');
$avricon-at-symbol: unicode('e9a3');
$avricon-credit-card: unicode('e9a4');
$avricon-tags: unicode('e9a5');
$avricon-idea: unicode('e9b6');
$avricon-tilde-circle: unicode('e9c9');
$avricon-arrow-forward-long: unicode('e9de');
$avricon-avrios: unicode('e9da');
$avricon-pricing-packages: unicode('e9db');
$avricon-star: unicode('e9df');
$avricon-delete: unicode('e96d');
$avricon-compass: unicode('e987');
$avricon-expand: unicode('e98d');
$avricon-automatic-import: unicode('e9ae');
$avricon-manual-import: unicode('e9be');
$avricon-piggy-bank: unicode('e9c1');
$avricon-tire: unicode('e9c3');
$avricon-swap-body: unicode('e9c6');
$avricon-chevron-left-s: unicode('e9c8');
$avricon-chevron-up-s: unicode('e9cb');
$avricon-chevron-down-s: unicode('e9d3');
$avricon-chevron-right-s: unicode('e9dd');