/*
  Allow angular.js to be loaded in body, hiding cloaked elements until
  templates compile.  The !important is important given that there may be
  other selectors that are more specific or come later and might alter display.
 */
[ng\:cloak],
[ng-cloak],
.ng-cloak {
  display: none !important;
}

::selection {
  color: $avr-color-blue-50;
  background: rgba($avr-color-blue-70, 0.15);
}

// Splash screen
.splash {
  display: none;
}

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  text-align: center;
}

[ng-cloak].splash {
  display: block !important;
  background-color: $avr-color-white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  filter: alpha(opacity=80);
  opacity: 0.8;
}

// Global add-ons
.sub-header {
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.badge.pull-right {
  margin-left: 15px;
}

// Text sizes
p.larger {
  font-size: $avr-font-size-large;
}

.container.var-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.modal-content-padding {
  padding-left: $avr-content-padding;
  padding-right: $avr-content-padding;
}

// No padding. Does what it says on the tin
.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.blurred {
  filter: blur(10px);
}

// adjust select2 style
.select2,
.select2-container--default .select2-selection--multiple,
.select2-dropdown {
  border-radius: 0;
  font-size: 12px;
  min-height: 30px;
}

label.left-label {
  text-align: left !important;
}

td.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// pointer for links with ng-click but without href
[ng-click],
[data-ng-click],
[x-ng-click] {
  cursor: pointer;
}

// No pointer for cards unless they have the click-able class
.card-item[ng-click] {
  cursor: auto;
}

// no pointer for modals
.modal[ng-click] {
  .modal-content {
    cursor: auto;
  }
}

// pointer for links with ng-href but without href
[ng-href],
[data-ng-href],
[x-ng-href] {
  cursor: pointer;
}

// General mixins
.clickable {
  cursor: pointer;
}

.selected-page-size {
  font-weight: $avr-font-weight-semibold;
}

.vin {
  text-transform: uppercase;
}

li.delete-item {
  a,
  span,
  a:hover {
    color: $brand-danger;
  }
}

.icon-lg {
  width: $avr-icon-size-l;
  height: $avr-icon-size-l;
}

.comment-block {
  font-family: monospace;
  white-space: pre-wrap;
}

/*
 * This has to stay here because this class is used outside of the BasicEmptyStateComponent
 */
.empty-state__btn {
  margin-top: $avr-space-xl;
}

body.deferred-bootstrap-error {
  .main-layout {
    display: none;
  }

  #initError {
    display: block;
  }
}

.pre {
  white-space: pre-wrap;
}
