@import 'libs/shared/src/styles/component-common';

.overlay-layout {
  .form-control:not(textarea) {
    height: 45px;
  }

  .form-control-static {
    padding-top: 12px;
  }

  .btn-primary,
  .btn-default {
    display: block;
    width: 100%;
    height: 45px;
  }

  .form-actions {
    margin-top: $avr-space-xl;
    margin-bottom: $avr-space-s;
  }
}
