@import 'libs/shared/src/styles/component-common';

.input-checkbox {
  display: inline-block;
  font-size: 0;
  position: relative;

  & &__input {
    // The checkbox still needs to be visible to the browser
    // for the validation to be working
    position: absolute;
    width: 0;
    height: 0;
    top: 1px;
    left: 3px;
    appearance: none;

    &:checked:not([disabled]) + .input-checkbox__label:before {
      content: $avricon-check;
      border-color: $btn-primary-border;
      box-shadow: 0 1px 1px 0 rgba($avr-color-blue-30, 0.2);
      background-color: $btn-primary-bg;
      background-image: $btn-gradient;

      @at-root .input-checkbox--danger#{&} { // stylelint-disable-line max-nesting-depth
        border-color: $btn-danger-border;
        box-shadow: 0 1px 1px 0 rgba($avr-color-red-30, 0.2);
        background-color: $btn-danger-bg;
      }
    }

    &[disabled] {
      + .input-checkbox__label:before {
        box-shadow: none;
        border-color: $avr-color-gray-90;
        background-color: $avr-color-gray-95;
        background-image: none;
      }

      &:checked + .input-checkbox__label:before {
        content: $avricon-check;
        color: $avr-color-gray-30;
      }
    }

    &:focus {
      + .input-checkbox__label:before {
        border-color: $btn-primary-border;
        box-shadow: 0 0 0 1px $btn-primary-border;

        @at-root .input-checkbox--danger#{&} { // stylelint-disable-line max-nesting-depth
          border-color: $btn-danger-border;
          box-shadow: 0 0 0 1px $btn-danger-border;
        }
      }

      &:checked + .input-checkbox__label:before {
        box-shadow: 0 0 0 1px $btn-primary-border;

        @at-root .input-checkbox--danger#{&} { // stylelint-disable-line max-nesting-depth
          box-shadow: 0 0 0 1px $btn-danger-border;
        }
      }
    }
  }

  & &__label {
    @include avricon-base;

    font-size: 14px;
    position: relative;
    cursor: pointer;
    padding-left: $avr-space-l;
    margin-bottom: 0;
    height: 20px;

    &--without-text {
      padding-left: 16px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      color: $avr-color-white;
      font-size: 9px;
      padding: 2px;
      border: $avr-border-width-100-gray-50;
      box-shadow: 0 1px 1px 0 rgba($avr-color-gray-50, 0.2);
      background-image: $btn-gradient;
      border-radius: $avr-border-radius-25;
      text-align: center;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }
}
