@mixin avricon-base {
  display: inline-block;

  /* stylelint-disable */
  &:before {
    position: relative;
    display: inline-block;
    text-decoration: underline;
    font-family: 'avricons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  /* stylelint-enable */

  // Fix for IE removing underline on icons (adding and removing text-decoration helps)
  &:before,
  &:hover:before {
    text-decoration: none;
  }
}

%avricon-base {
  @include avricon-base;
}

@mixin shine {
  background: $avr-color-gray-95;
  background-image: linear-gradient(to right, $avr-color-gray-95 0%, $avr-color-gray-90 20%, $avr-color-gray-95 40%, $avr-color-gray-95 100%);
  background-repeat: no-repeat;
  background-size: 250%;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -50% 0;
  }
}
