$avr-button-height: $padding-base-vertical * 2 + $line-height-base * $avr-font-size-base + 2;

@mixin generate-button-styles($state, $background, $background-hover, $background-active, $background-disabled, $border, $border-hover, $border-active) {
  &.btn-#{$state}:disabled {
    @if $state == 'default' {
      background-color: $background-disabled;
      color: $avr-color-gray-80;
      border: $avr-border-width-100-gray-30;
    }

    @else {
      background-color: $background-disabled;
      color: $avr-color-white;
      border: 1px solid transparent;
    }
  }

  &.btn-#{$state}:not([disabled]) {
    background-image: none;
    border: 1px solid $border;

    &:hover {
      background-color: $background-hover;
      border: 1px solid $border-hover;
    }

    &:focus {
      background-image: none;
      background-color: $background;
      border: 1px solid $border;
    }

    &:active,
    &.active {
      box-shadow: inset 0 0 0 1px $border-active;
      border: 1px solid $border-active;
      background-image: none;
      background-color: $background-active;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;

  padding: {
    top: 7px;
    bottom: 7px;
  }

  &.btn-sm {
    padding: {
      top: $avr-space-xs;
      bottom: $avr-space-xs;
    }
  }

  &--no-border-radius {
    border-radius: 0;
  }

  &:focus:active {
    outline: none;
  }

  @include generate-button-styles(
    $state: 'default',
    $background: $btn-default-bg,
    $background-hover: $btn-default-bg-hover,
    $background-active: $btn-default-bg-active,
    $background-disabled: $btn-default-bg-disabled,
    $border: $btn-default-border,
    $border-hover: $btn-default-border-hover,
    $border-active: $btn-default-border-active,
  );
  @include generate-button-styles(
    $state: 'primary',
    $background: $btn-primary-bg,
    $background-hover: $btn-primary-bg-hover,
    $background-active: $btn-primary-bg-active,
    $background-disabled: $btn-primary-bg-disabled,
    $border: $btn-primary-border,
    $border-hover: $btn-primary-border-hover,
    $border-active: $btn-primary-border-active,
  );
  @include generate-button-styles(
    $state: 'success',
    $background: $btn-success-bg,
    $background-hover: $btn-success-bg-hover,
    $background-active: $btn-success-bg-active,
    $background-disabled: $btn-success-bg-disabled,
    $border: $btn-success-border,
    $border-hover: $btn-success-border-hover,
    $border-active: $btn-success-border-active,
  );
  @include generate-button-styles(
    $state: 'danger',
    $background: $btn-danger-bg,
    $background-hover: $btn-danger-bg-hover,
    $background-active: $btn-danger-bg-active,
    $background-disabled: $btn-danger-bg-disabled,
    $border: $btn-danger-border,
    $border-hover: $btn-danger-border-hover,
    $border-active: $btn-danger-border-active,
  );
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .btn-group {
    a {
      font-weight: $avr-font-weight-normal;
    }
  }
}

.btn,
.btn.active,
button,
a {
  &:focus {
    outline: none;
  }
}

.btn-tooltip {
  border-color: transparent;
  background: none;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.btn-link.btn-inline {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-link.btn-link--compact {
  padding: 0;
}

.btn-link.btn-small {
  padding: 0;
  font-size: $avr-font-size-small;
}

.btn-link {
  &:disabled {
    color: $avr-color-gray-80;
  }

  &--danger {
    color: $avr-color-red-40;

    &:hover {
      color: $avr-color-red-30;
    }
  }

  &--warning {
    color: $avr-color-yellow-40;

    &:hover {
      color: $avr-color-yellow-30;
    }
  }

  &--success {
    color: $avr-color-green-40;

    &:hover {
      color: $avr-color-green-30;
    }
  }

  &--muted {
    color: $avr-color-gray-30;

    &:hover {
      color: $avr-color-gray-10;
    }
  }
}

table {
  .btn-link.btn-inline {
    margin: 0;
  }
}

.btn-group-flex {
  display: flex;

  > .btn {
    flex: 1;
    white-space: normal;
  }
}

.btn-default.form-control {
  border-color: $input-border;
}
