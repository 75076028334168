$avr-standalone-service-layout-header-spacing: $avr-space-xl;
$avr-standalone-service-layout-logo-height: 31px;

body.standalone-service-layout {
  background: none;
}

.standalone-service-layout {
  .container {
    padding-top: $avr-space-xl;
    padding-bottom: $avr-space-xl;
  }

  .crushed-it {
    width: 300px;
  }

  h2 + p + form {
    margin-top: $avr-space-xl;
  }

  .col-md-6 h2 {
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }

  // Styling of generic ajax request loading bar
  .page-loading-bar {
    background: transparent;

    .bar {
      background-color: $avr-color-blue-50;
    }
  }

  .alert-api-error.off-screen {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
  }
}
