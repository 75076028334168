.header-desc {
  @include clearfix;

  @media (max-width: $screen-sm-min) {
    margin-top: $avr-space-m;
  }

  .label {
    display: inline-block;
    margin-top: $avr-space-base;
  }
}

h1,
h2 {
  @media (max-width: $screen-sm-min) {
    .btn-group.pull-right {
      float: none !important;
      display: block;
      text-align: right;
      margin-top: $avr-space-m;

      button {
        float: none;
        margin-bottom: $avr-space-s;
      }
    }
  }
}

.page-container {
  padding-left: $avr-space-m;
  padding-right: $avr-space-m;

  @media (min-width: $screen-lg-min) {
    margin-left: 8.33%;
    width: 83.33%;
  }

  @media (min-width: $screen-xl-min) {
    margin-left: 16.67%;
    width: 66.67%;
  }
}

.page-section {
  margin-top: $avr-space-base * 2;

  @include clearfix;
}

.header-action {
  font-size: $avr-font-size-base;
  font-weight: initial;
  color: $text-color;
  padding-left: 0;
  padding-right: 0;
  display: block;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    color: $text-color;
  }
}

avr-header-action {
  clear: both;
  position: relative;
  float: right;
}

@media (min-width: $screen-sm-min) {
  avr-header-action {
    clear: none;
  }

  avr-add-button,
  avr-header-action {
    & + avr-header-action {
      margin-right: $avr-space-m * 2 + 3;

      &:after {
        content: '';
        display: block;
        position: absolute;
        background: $avr-color-gray-90;
        width: 3px;
        right: -$avr-space-m - 3px;
        top: 0;
        height: 100%;
      }
    }
  }
}
