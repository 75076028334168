$avricon-colorful-size: 16px;

.avricon-colorful-link {
  display: inline-block;
  padding-left: $avricon-colorful-size * 1.4;
  background: {
    position: center left;
    size: $avricon-colorful-size $avricon-colorful-size;
    repeat: no-repeat;
  }
}

.avricon-colorful {
  display: inline-block;
  width: $avricon-colorful-size;
  height: $avricon-colorful-size;
  background-size: $avricon-colorful-size $avricon-colorful-size;
  vertical-align: middle;

  &--lg {
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }
}

.avricon-colorful-paper-report-xls {
  background-image: url('~libs/shared/src/assets/images/icons/paper-report-xls.svg');
}

.avricon-colorful-check-icon-report {
  background-image: url('~libs/shared/src/assets/images/icons/check-icon-report.svg');
}

.avricon-colorful-field-icon-numeric {
  @extend %avricon-base;
  @extend .avricon-hash;

  color: $avr-color-green-50;
}

.avricon-colorful-field-icon-time-based {
  @extend %avricon-base;
  @extend .avricon-calendar-blank;

  color: $avr-color-yellow-40;
}

.avricon-colorful-field-icon-other {
  @extend %avricon-base;
  @extend .avricon-string;

  color: $avr-color-blue-50;
}

.avricon-colorful-field-icon-boolean {
  @extend %avricon-base;
  @extend .avricon-icon-yes-no;

  color: $avr-color-gray-30;
}
