.badge {
  line-height: 1.2;
  font-weight: $avr-font-weight-normal;

  // If the badge is used in a button
  .btn & {
    color: $avr-color-white;
  }

  &.badge--inverse {
    background-color: $avr-color-gray-90;
    color: $avr-color-gray-10;
  }

  &.badge-success {
    background: $avr-color-green-50;

    &.badge--inverse {
      background: $avr-color-green-90;
      color: $avr-color-green-40;
    }
  }

  &.badge-warning {
    background: $avr-color-yellow-50;

    &.badge--inverse {
      background: $avr-color-yellow-90;
      color: $avr-color-yellow-40;
    }
  }

  &.badge-info {
    background: $avr-color-blue-50;

    &.badge--inverse {
      background: $avr-color-blue-90;
      color: $avr-color-blue-40;
    }
  }

  &.badge-danger {
    background: $avr-color-red-50;

    &.badge--inverse {
      background: $avr-color-red-90;
      color: $avr-color-red-40;
    }
  }

  &.badge-beta {
    background-color: $badge-slightly-transparent;
  }

  &.badge-lg {
    padding: 5px 10px;
    border-radius: 12px;
  }

  &.badge--with-icon {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px 4px 5px;

    .avricon {
      margin-top: -1px;
      font-size: $avr-font-size-smallish;
    }
  }

  &.badge--dat {
    position: relative;
    color: $avr-color-gray-30;
    background: $avr-color-gray-90;
  }
}

.unread-dot {
  display: block;
  position: relative;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: $avr-color-red-50;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
  }

  &--success:before {
    background-color: $avr-color-green-50;
  }

  &--warning:before {
    background-color: $avr-color-yellow-50;
  }
}

.badge-text {
  padding: $avr-space-xs $avr-space-s $avr-space-xs $avr-space-l;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    left: $avr-space-s;
    top: 7px;
    border-radius: 50%;
  }

  &.badge-text-near-button {
    margin-right: $avr-space-xl;
    margin-left: -$avr-space-s;
  }

  &.badge-text-danger {
    background-color: $badge-danger-light;
    color: $badge-danger-dark;

    &:before {
      background-color: $avr-color-red-50;
    }

    &:hover {
      color: $badge-danger-dark;
    }
  }

  &.badge-text-success {
    background-color: $avr-color-green-90;
    color: $avr-color-green-30;

    &:before {
      background-color: $avr-color-green-50;
    }

    &:hover {
      color: $avr-color-green-30;
    }
  }
}
