.advice-box {
  text-align: center;

  h4 {
    font-size: $avr-font-size-base;
    font-weight: $avr-font-weight-semibold;
  }
}

// avr-animate-on-reload - fades content in and out during update
.updateable {
  // MODE=CONTENT making content semi-transparent on reload
  &.var-content {
    transition: opacity $avr-animation-duration ease-out 0.15s;

    &.reloading {
      opacity: 0.2;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: $z-index-layer-5;
      }

      .table > tr > td {
        border-color: rgba($table-border-color, 0.2);
      }
    }
  }

  // MODE=ICON busy animation on icon
  &.var-icon {
    & > *,
    .avricon {
      transition: transform 0.1s, opacity 0.1s;
    }

    &.reloading {
      & > *,
      .avricon {
        transform: scale(0.85);
        opacity: 0.8;
      }
    }
  }
}

.avricon.expired {
  color: $avr-color-brand-black;
}

avr-expandable {
  display: block;
}

.pools-contact-box {
  h3,
  p {
    margin: 0;
  }

  h4 {
    margin-top: 0;
  }

  h3 + p,
  h4 + p {
    margin-bottom: $avr-space-xl;
  }

  .font-semibold {
    font-weight: $avr-font-weight-semibold;
  }

  .avricon {
    color: $avr-color-blue-40;
  }

  div p:last-child {
    margin-top: $avr-space-m;
  }
}

.wrap-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
